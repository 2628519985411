import React from 'react';
import { styled } from '@mui/material/styles';
import { OngoingRevisions } from './OngoingRevisions';
import { LastRevisedGuides } from './LastRevisedGuides';
import { CompletedRevisions } from './CompletedRevisions';
import { PopularGuides } from './PopularGuides';
import { OpenTasks } from './OpenTasks';
import { RegulationChanges } from './RegulationChanges';
import { Notices } from './Notices';
import { SearchTermStats } from './SearchTermStats';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';

const PREFIX = 'Overview';

const classes = {
  sectionHeader: `${PREFIX}-sectionHeader`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.sectionHeader}`]: {
    paddingTop: theme.spacing(1.5),
    color: theme.palette.text.secondary,
    fontSize: '1.2rem',
  },
}));

export function Overview() {

  return (
    <StyledGrid container>
      <Grid item xs={4}>
        <OpenTasks />
      </Grid>
      <Grid item xs={4}>
        <RegulationChanges />
      </Grid>
      <Grid item xs={4}>
        <Notices />
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h5" className={classes.sectionHeader}>
          Aktivitet
        </Typography>
        <CompletedRevisions />
        <LastRevisedGuides />
        <OngoingRevisions />
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h5" className={classes.sectionHeader}>
          Brukerstatistikk
        </Typography>
        <PopularGuides />
        <SearchTermStats />
      </Grid>
    </StyledGrid>
  );
}
