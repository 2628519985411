import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Checkbox, Typography } from '@mui/material';
import { Button } from '@mui/material';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { sumScore } from './BacklogModel';
import { Chart } from './Chart';
import { GET_ALL } from './backlog.graphql';
import { useQuery } from '@apollo/client';
import LoadingSpinner from '../LoadingSpinner';
import { GetAllBacklogItemsQuery } from '../__generated__/graphql';
import { Unpacked } from '../graphQLTypes/types';
import { CriteriaType } from './types';
import { Modal } from '../dialogs/useModal';

export interface RankDetailsDialogProps {
  guideId: string;
  docName: string | null | undefined;
  docTitle: string | null | undefined;
  ignoreCriteria?: CriteriaType[];
}

type BacklogItem = Unpacked<GetAllBacklogItemsQuery['guides']>;

export function RankDetailsDialog({ modal }: { modal: Modal<RankDetailsDialogProps, unknown> }) {
  const { guideId, docName, docTitle, ignoreCriteria } = modal.data;
  const { data, loading } = useQuery(GET_ALL);
  return (
    <Dialog open={true} onClose={() => modal.close()} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth>
      <DialogTitle id="form-dialog-title">
        <Typography variant="h6" component="div" noWrap style={{ maxWidth: 900 }}>{`Prioriteringskriterier for ${docName} - ${docTitle}`}</Typography>
      </DialogTitle>
      <DialogContent>
        {loading && <LoadingSpinner />}
        {data?.guides && <Details itemId={guideId} items={data.guides} ignoreCriteria={ignoreCriteria ?? []} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => modal.close()} variant="contained">
          Lukk
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function Details({ itemId, items, ignoreCriteria }: { itemId: string; items: BacklogItem[]; ignoreCriteria: CriteriaType[] }) {
  const [localIgnoreCriteria, setLocalIgnoreCriteria] = React.useState<CriteriaType[]>(ignoreCriteria);
  const item = items.find((x) => x!.id === itemId)!;

  const isPriorityParameterActive = (id: CriteriaType) => !localIgnoreCriteria.includes(id);
  const togglePriorityParameter = (id: CriteriaType) =>
    setLocalIgnoreCriteria(isPriorityParameterActive(id) ? [...localIgnoreCriteria, id] : localIgnoreCriteria.filter((x) => x !== id));

  const renderPlanningscore = (title: string, criteria: CriteriaType) => {
    const planningItem = item!.planningScores![criteria];
    if (!planningItem) return null;
    return (
      <TableRow>
        <TableCell>
          <Checkbox color="primary" checked={isPriorityParameterActive(criteria)} onChange={() => togglePriorityParameter(criteria)} />
        </TableCell>
        <TableCell>{title}</TableCell>
        <TableCell align="right">{planningItem.value}</TableCell>
        <TableCell align="right">{planningItem.boost}</TableCell>
        <TableCell align="right">{planningItem.score}</TableCell>
      </TableRow>
    );
  };

  return (
    <>
      <Chart items={items} ignoreCriteria={localIgnoreCriteria} currentItem={item} />
      <Table padding="none">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Navn</TableCell>
            <TableCell align="right">Verdi</TableCell>
            <TableCell align="right">Vekting</TableCell>
            <TableCell align="right">Poengsum</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {renderPlanningscore('Utgave', 'edition')}
          {renderPlanningscore('Visninger', 'pageViews')}
          {renderPlanningscore('Merknader', 'notices')}
          {renderPlanningscore('Prioritert', 'needForRevision')}
          <TableRow>
            <TableCell>
              <b>Totalt</b>
            </TableCell>
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell align="right" sx={{ fontWeight: 'bold' }}>
              {sumScore(item, localIgnoreCriteria)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
}
