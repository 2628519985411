import { Typography, Tabs, Tab, FormControlLabel, Switch, Grid } from '@mui/material';
import Fuse from 'fuse.js';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActiveGuidesTable, PlannedGuidesTable, ExpiredGuidesTable, UnpublishedGuidesTable } from './GuideTable';
import { selectGuideFilter, showOnlyMine as setShowOnlyMine, showTab } from './filterSlice';
import { GET_GUIDES } from './guide.graphql';
import { SearchField } from '../components/SearchField';
import { useQuery } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';
import LoadingSpinner from '../LoadingSpinner';

import { NewGuideButton } from './NewGuideDialog';
import { GetGuidesQuery } from '../__generated__/graphql';
import { Unpacked } from '../graphQLTypes/types';
import { useAuth } from 'oidc-react';
import { StyledPaper } from '../theme';

export type Guide = Unpacked<GetGuidesQuery['guides']>;

export function Overview() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState('');
  const { showOnlyMine, tab } = useSelector(selectGuideFilter);
  const auth = useAuth();
  const userId = auth.userData?.profile.sub;
  const showOnlyMineFromParam = searchParams.get('mine') === '1';
  const { loading, data } = useQuery(GET_GUIDES, { fetchPolicy: `cache-and-network` });

  if (!showOnlyMine && showOnlyMineFromParam) dispatch(setShowOnlyMine(true));

  const filteredGuides = data?.guides ? filterGuides(data.guides, searchTerm, showOnlyMine ? userId : undefined) : [];

  return (
    <StyledPaper>
      <Grid direction="row" container spacing={3}>
        <Grid item xs={2}>
          <Typography variant="h5">Anvisninger</Typography>
        </Grid>
        <Grid item xs={10} style={{ textAlign: 'left', marginTop: -61, marginLeft: 127 }}>
          <NewGuideButton />
        </Grid>

        <Grid item xs={12}>
          <SearchField filter={setSearchTerm} />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Switch name="showOnlyMine" onChange={(_, checked) => dispatch(setShowOnlyMine(checked))} checked={showOnlyMine} />}
            label="Mine"
          />
        </Grid>
        <Grid item xs={12}>
          <Tabs value={tab} onChange={(_, val) => dispatch(showTab(val))}>
            <Tab label="Aktive" />
            <Tab label="Nye" />
            <Tab label="Tilbaketrukne" />
            <Tab label="Upubliserte  anvisninger" />
          </Tabs>
          {loading && !data && <LoadingSpinner />}
          {tab === 0 && <ActiveGuidesTable guides={filteredGuides} />}
          {tab === 1 && <PlannedGuidesTable guides={filteredGuides} />}
          {tab === 2 && <ExpiredGuidesTable guides={filteredGuides} />}
          {tab === 3 && <UnpublishedGuidesTable guides={filteredGuides} />}
        </Grid>
      </Grid>
    </StyledPaper>
  );
}

function filterGuides(guides: Guide[], searchTerm: string, userId?: string): Guide[] {
  const filteredGuides = userId ? guides && guides.filter((x) => x && x.owner && x.owner.subjectId === userId) : guides;
  if (!searchTerm) return filteredGuides;

  let options = {
    shouldSort: false,
    threshold: 0,
    ignoreLocation: true,
    minMatchCharLength: 3,
    keys: ['docName', 'docNumber', 'docTitle', 'mainCategory.title', 'owner.name'],
  };

  const fuse = new Fuse(filteredGuides, options);
  return fuse.search(searchTerm).map((x) => x.item);
}
