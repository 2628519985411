import { useEffect, useMemo, useState } from 'react';
import { useConfig } from '../../../env';
import { TiptapCollabProvider, WebSocketStatus } from '@hocuspocus/provider';
import { useAuth } from 'oidc-react';
import { CollaborationUser } from '../../../../shared/guide-cms/types/collaborationUser';
import { StatelessMessage, StatelessMessageType } from '../../../../shared/guide-cms/types/stateless';
import { useGuideCmsContext } from '../GuideCmsContext';

export interface ConnectionStatus {
  isConnected: boolean;
  isConnecting: boolean;
}

export interface SaveStatus {
  isSaving: boolean;
  invalidSchemaError: boolean;
  error?: string;
  savedAt?: string;
}

export const useHocuspocus = (guideId: string) => {
  const { schemaVersion } = useGuideCmsContext();
  const [isConnected, setIsConnected] = useState(false);
  const [isConnecting, setIsConnecting] = useState(true);
  const [connectedUsers, setConnectedUsers] = useState<CollaborationUser[]>([]);
  const [saveStatus, setSaveStatus] = useState<SaveStatus>({ isSaving: false, invalidSchemaError: false });

  const config = useConfig();
  const auth = useAuth();

  const provider = useMemo(() => {
    if (!auth.userData?.access_token) {
      console.log('Missing auth token. Will not connect to Hocuspocus');
      return undefined;
    }
    console.log(`Connected to Hocuspocus with Guide ID ${guideId} on schema version ${schemaVersion}`);
    const provider = new TiptapCollabProvider({
      baseUrl: config?.hocuspocusUrl ?? '',
      name: `${guideId}:${schemaVersion}`,
      token: auth.userData?.access_token,
      onStatus(data) {
        if (data.status === WebSocketStatus.Connecting) {
          setIsConnecting(true);
          setIsConnected(false);
        }
      },
      onDisconnect() {
        setIsConnecting(false);
        setIsConnected(false);
      },
      onConnect() {
        setIsConnecting(false);
        setIsConnected(true);
      },
      onAuthenticationFailed(data) {
        setIsConnecting(false);
        setIsConnected(false);
      },
      onAwarenessUpdate(data) {
        const users = data.states.map((state) => state.user as CollaborationUser);
        setConnectedUsers(users);
      },
      onStateless(data) {
        const message = JSON.parse(data.payload) as StatelessMessage;
        switch (message.type) {
          case StatelessMessageType.SaveStart:
            setSaveStatus({
              ...saveStatus,
              isSaving: true,
              invalidSchemaError: false,
              error: undefined,
              savedAt: undefined,
            });
            break;
          case StatelessMessageType.SaveSuccess:
            // Add a small delay so that the status change will be visible to the user
            setTimeout(() => {
              setSaveStatus({
                ...saveStatus,
                isSaving: false,
                error: undefined,
                savedAt: message.savedAt,
              });
            }, 1000);
            break;
          case StatelessMessageType.SaveError:
            setSaveStatus({
              ...saveStatus,
              isSaving: false,
              error: message.error,
            });
            break;
          case StatelessMessageType.InvalidSchemaVersion:
            setSaveStatus({
              ...saveStatus,
              isSaving: false,
              invalidSchemaError: true,
              error: 'Invalid schema version',
            });
            break;
        }
      },
    });
    return provider;
  }, [guideId, schemaVersion, config?.hocuspocusUrl, auth.userData?.access_token]);

  useEffect(() => {
    return () => {
      // Close connections to Hocuspocus when component is unmounted.
      // NOTE: If React.StrictMode is used, this will break as cleanup function will be called twice.
      provider?.destroy();
    };
  }, [provider]);
  const connectionStatus: ConnectionStatus = {
    isConnected,
    isConnecting,
  };

  return {
    provider,
    connectionStatus,
    connectedUsers,
    saveStatus,
  };
};
