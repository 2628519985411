import React from 'react';
import { useNavigate } from 'react-router-dom';

import { BackButton, ListLabel, StyledLink } from '../Components';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import { List, ListItem, ListItemText, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Comments } from './Comments';
import { DELETE, GET, SET_OWNER, SET_DEPUTY_OWNER } from './categories.graphql';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import LoadingSpinner from '../LoadingSpinner';
import { ColWidths, TableCellEllipsis, TableCellHead, TableCellIcon } from '../components/TableComponents';
import { formatDateOnly } from '../Formatters';
import { GuideStatusIcon } from '../guides/GuideStatusIcon';
import { GET_GUIDES } from '../guides/guide.graphql';
import { DeleteForever } from '@mui/icons-material';
import { UserSelector } from '../users/UserSelector';
import { GetCategoryQuery } from '../__generated__/graphql';
import { Unpacked } from '../graphQLTypes/types';
import { Guide } from '../guides/Overview';
import { StyledPaper } from '../theme';
import { LicensePageViewStatForCategoryDialog, LicensePageViewStatForCategoryDialogProps } from '../guides/LicensePageViewStatDialog';
import { useModal } from '../dialogs/useModal';
import { EditCategoryDialog } from './EditCategoryDialog';

type Category = Unpacked<GetCategoryQuery['category']>;
export function Details() {
  const { id } = useParams();
  const { data, loading } = useQuery(GET, { variables: { id } });

  if (loading) return <LoadingSpinner />;

  if (!data || !data.category) return null;

  return <CategoryPage category={data.category} />;
}

function CategoryPage({ category }: { category: NonNullable<Category> }) {
  const statModal = useModal<LicensePageViewStatForCategoryDialogProps, undefined>({ data: category });
  const editModal = useModal<Category, undefined>({ data: category });

  const showPageViewsPerLicense = () => {
    statModal.open();
  };

  return (
    <Grid container>
      <Grid item xs={6}>
        <Typography variant="h5">
          <BackButton />
          {category.title}
          <IconButton title="Endre tekst" onClick={() => editModal.open()} size="large">
            <EditIcon />
          </IconButton>
        </Typography>
      </Grid>
      <Grid item xs={6} style={{ textAlign: 'right' }}>
        <Button onClick={() => showPageViewsPerLicense()}>Visninger per kunde</Button>
      </Grid>
      <Grid item xs={12}>
        <CategoryDetails category={category} />
      </Grid>
      <Grid item xs={12}>
        <Comments category={category} />
      </Grid>
      <Grid item xs={12}>
        <UsedIn category={category} />
      </Grid>
      {statModal.isOpen && <LicensePageViewStatForCategoryDialog modal={statModal} />}
      {editModal.isOpen && <EditCategoryDialog modal={editModal} />}
    </Grid>
  );
}

function CategoryDetails({ category }: { category: Category }) {
  const navigate = useNavigate();
  const [setOwner] = useMutation(SET_OWNER);
  const [setDeputyOwner] = useMutation(SET_DEPUTY_OWNER);
  const [deleteCategory] = useMutation(DELETE, { variables: { input: { id: category?.id } } });

  const handleDelete = async () => {
    await deleteCategory();
    navigate('/category');
  };

  if (!category) return null;

  return (
    <StyledPaper>
      <Typography variant="h5" component="h3">
        Detaljer
      </Typography>
      <List>
        <ListItem>
          <ListLabel>Beskrivelse på nett:</ListLabel>
          <ListItemText primary={category.description} />
        </ListItem>
        <ListItem>
          <ListLabel>Eier:</ListLabel>
          <ListItemText>
            <UserSelector
              userId={category.ownerId}
              onChange={(userId) => setOwner({ variables: { input: { id: category.id, userId } } })}
              allowNull={false}
            />
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListLabel>Viseansvarlig:</ListLabel>
          <ListItemText>
            <UserSelector
              userId={category.displayManagerId}
              onChange={(userId) => setDeputyOwner({ variables: { input: { id: category.id, userId } } })}
            />
          </ListItemText>
        </ListItem>
      </List>
      {category.guideRevisions?.length === 0 && (
        <Button variant="contained" color="primary" onClick={handleDelete} aria-label="Remove category" startIcon={<DeleteForever />}>
          Fjern fagområde
        </Button>
      )}
    </StyledPaper>
  );
}

function UsedIn({ category }: { category: Category }) {
  const { data, loading } = useQuery(GET_GUIDES);
  if (!category) return null;
  if (loading || !data) return <LoadingSpinner />;

  const guides = data?.guides?.filter((x) => category.guideRevisions?.includes(x!.id));

  const mainCategory = guides?.filter((x) => x!.mainCategory?.title === category.title);
  const subCategory = guides?.filter((x) => x!.mainCategory?.title !== category.title);
  return (
    <>
      <StyledPaper>
        <Typography variant="h5" component="h3">
          Hovedfagområde
        </Typography>
        <GuideTable guides={mainCategory} />
      </StyledPaper>
      <StyledPaper>
        <Typography variant="h5" component="h3">
          Underfagområde
        </Typography>
        <GuideTable guides={subCategory} />
      </StyledPaper>
    </>
  );
}

function GuideTable({ guides }: { guides: Guide[] }) {
  return (
    <Table size="small" sx={{ marginBottom: 1 }}>
      <ColWidths widths={[40, 120, null, 150, 40]} />
      <TableHead>
        <TableRow>
          <TableCellHead>Status</TableCellHead>
          <TableCellHead>Nummer</TableCellHead>
          <TableCellHead>Tittel</TableCellHead>
          <TableCellHead>Fagområde</TableCellHead>
          <TableCellHead>Sist publisert</TableCellHead>
        </TableRow>
      </TableHead>
      <TableBody>
        {guides.map((x) => {
          if (!x) return null;
          return (
            <TableRow key={x.id}>
              <TableCellIcon>
                <GuideStatusIcon
                  status={x.status!}
                  hasRevision={x.ongoingRevision !== null}
                  hasChangesSinceLastPublish={x.hasChangesSinceLastPublish}
                />
              </TableCellIcon>
              <TableCell>
                <StyledLink to={`/guide/${x.id}`}>{x.docName}</StyledLink>
              </TableCell>
              <TableCellEllipsis title={x.docTitle}>{x.docTitle}</TableCellEllipsis>
              <TableCellEllipsis title={x.mainCategory?.title}>{x.mainCategory?.title}</TableCellEllipsis>
              <TableCell>{formatDateOnly(x.lastPublishedAt)}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
