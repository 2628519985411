import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { Filter1, Filter2, Filter3, Filter4, Filter5 } from '@mui/icons-material/';
import { useMutation } from '@apollo/client';
import { SET_NEED_FOR_REVISION } from './backlog.graphql';
import { NeedForRevision } from '../__generated__/graphql';
import { Modal } from '../dialogs/useModal';

interface Need {
  priority: NeedForRevision;
  tooltip: string;
  icon: React.ReactNode;
}

export interface NeedForRevisionDialogInput {
  id: string;
  needForRevision?: NeedForRevision | null;
  needForRevisionComment?: string | null;
}

export function NeedForRevisionDialog({ modal }: { modal: Modal<NeedForRevisionDialogInput, unknown> }) {
  const guide = modal.data;
  const [comment, setComment] = useState(guide.needForRevisionComment);
  const [needForRevision, setNeedForRevision] = useState(guide.needForRevision);

  const [updateNeedForRevision, { loading }] = useMutation(SET_NEED_FOR_REVISION, {
    variables: { input: { id: guide.id, needForRevision: needForRevision!, comment } },
  });

  const handleClose = () => {
    modal.close();
  };

  const handleOk = async () => {
    await updateNeedForRevision();

    handleClose();
  };

  const renderNeedForRevision = () => {
    const needs: Need[] = [
      { priority: NeedForRevision.Critical, tooltip: needForRevisionDescriptions[1], icon: <Filter1 /> },
      { priority: NeedForRevision.High, tooltip: needForRevisionDescriptions[2], icon: <Filter2 /> },
      { priority: NeedForRevision.Medium, tooltip: needForRevisionDescriptions[3], icon: <Filter3 /> },
      { priority: NeedForRevision.Low, tooltip: needForRevisionDescriptions[4], icon: <Filter4 /> },
      { priority: NeedForRevision.NextTime, tooltip: needForRevisionDescriptions[5], icon: <Filter5 /> },
    ];
    const needToColor = (need: Need) => (need.priority === needForRevision ? 'primary' : 'default');

    const updateNeedForRevision = (item: Need) => setNeedForRevision(item.priority);

    const renderButton = (item: Need) => (
      <IconButton key={item.priority} title={item.tooltip} onClick={() => updateNeedForRevision(item)} color={needToColor(item)} size="large">
        {item.icon}
      </IconButton>
    );

    return needs.reverse().map(renderButton);
  };

  return (
    <div>
      <Dialog open={true} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth>
        <DialogTitle id="form-dialog-title">Angi behov for revisjon</DialogTitle>
        <DialogContent>
          {renderNeedForRevision()}
          <TextField
            margin="dense"
            name="comment"
            label="Kommentar"
            type="text"
            multiline
            onChange={(e) => setComment(e.target.value)}
            value={comment ? comment : ''}
            fullWidth
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Avbryt</Button>
          <Button disabled={loading} onClick={handleOk} variant="contained" color="primary">
            Endre
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export const needForRevisionDescriptions = ['Ikke angitt', 'Kritisk', 'Høy prioritet', 'Viktig', 'Må vurderes', 'Husk til neste gang'];

export function NeedForRevisionLabel({ needForRevision }: { needForRevision: NeedForRevision | undefined }) {
  switch (needForRevision) {
    case NeedForRevision.Critical:
      return <span>Kritisk</span>;
    case NeedForRevision.High:
      return <span>Høy prioritet</span>;
    case NeedForRevision.Medium:
      return <span>Viktig</span>;
    case NeedForRevision.Low:
      return <span>Må vurderes</span>;
    case NeedForRevision.NextTime:
      return <span>Husk til neste gang</span>;
    default:
      return <span>Ikke angitt</span>;
  }
}
