import React from 'react';
import Grid from '@mui/material/Grid';

import { Changes } from './MyChangeLog';
import { MyLastChangedGuides } from './MyLastChangedGuides';
import { MyParticipationInRevisions } from './MyParticipationInRevisions';

export function Dashboard() {
  return (
    <Grid container>
      <Grid item xs={12}>
        <MyParticipationInRevisions />
      </Grid>
      <Grid item xs={6}>
        <MyLastChangedGuides />
      </Grid>
      <Grid item xs={6}>
        <Changes />
      </Grid>
    </Grid>
  );
}
