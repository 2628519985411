import { Editor } from '@tiptap/core';
import { Attrs, Node } from '@tiptap/pm/model';
import { NodeSelection, TextSelection, Selection } from '@tiptap/pm/state';
import { JSONContent } from '@tiptap/react';

export const getTipTapJsonContent = (content: string): JSONContent | null => {
  if (!content.startsWith('{')) {
    return null;
  }
  const parsed = JSON.parse(content) as JSONContent;
  return parsed;
};

export const getNodesOfType = (content: JSONContent, type: string[] | string): JSONContent[] => {
  return (
    content?.content?.reduce<JSONContent[]>((foundNodes, node) => {
      if ((typeof type === 'string' && type === node.type) || (typeof type === 'object' && type.includes(node.type ?? ''))) {
        return [...foundNodes, node, ...getNodesOfType(node, type)];
      }
      return foundNodes;
    }, []) ?? []
  );
};

export const getText = (content: JSONContent): string => {
  let initialText = content.text ?? '';
  const texts = getNodesOfType(content, 'text')
    .filter((node) => !!node.text)
    .map((node) => node.text);
  return initialText + texts.join('');
};

const findNodePos = (doc: Node, node: Node): number | null => {
  let result = null;
  doc.descendants((descendant, pos) => {
    if (descendant === node) {
      result = pos;
      return false; // Stop the iteration
    }
  });
  return result;
};

export const updateNodeAttributes = (editor: Editor, node: Node, newAttributes: Attrs) => {
  const { state, view } = editor;
  const { tr } = state;
  const pos = findNodePos(state.doc, node);
  if (pos !== null) {
    tr.setNodeMarkup(pos, undefined, {
      ...node.attrs,
      ...newAttributes,
    });
    view.dispatch(tr);
  }
};

export const getSelectedNodeAttributes = (selection: Selection): Attrs | null => {
  if (!selection) return null;
  if (selection instanceof NodeSelection) {
    return selection.node.attrs;
  }
  if (selection instanceof TextSelection) {
    const { $from } = selection;
    const parentNode = $from.node(-1);
    return parentNode.attrs;
  }
  return null;
};
