import { FormControlLabel, Grid, IconButton, DialogActions, DialogContent, DialogTitle, Dialog, CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Add } from '@mui/icons-material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import { useNavigate } from 'react-router-dom';
import { ADD_NEW_GUIDE, GET_GUIDES } from './guide.graphql';
import Alert from '@mui/material/Alert';
import { CategorySelect, Category } from '../categories/CategorySelect';
import { ClassificationIdentifier, ClassificationLabel } from '../classifications/ClassificationLabel';
import { GET_CLASSIFICATIONS } from '../classifications/classifications.graphql';
import { ClassificationCode } from '../__generated__/graphql';

export function NewGuideButton() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <IconButton onClick={() => setOpen(true)} title="Opprett ny BFS anvisning/BVN blad" size="large">
        <Add />
      </IconButton>
      {open && <NewGuideDialog onClose={() => setOpen(false)} onGuideCreated={(id) => navigate(`/guide/${id}`)} />}
    </>
  );
}

function NewGuideDialog({ onClose, onGuideCreated }: { onClose(): void; onGuideCreated(id: string): void }) {
  const [category, setCategory] = useState<Category | null>(null);
  const [classificationCode, setClassificationCode] = useState(ClassificationCode.Bfs);
  const [docTitle, setDocTitle] = useState('');
  const [docName, setDocName] = useState('');
  const [classification, setClassification] = useState<ClassificationIdentifier | undefined>(undefined);

  const [validDocNumber, setValidDocNumber] = useState(false);
  const [docNumberExists, setDocNumberExists] = useState(false);

  const handleClose = () => {
    clearField();
    onClose();
  };

  const clearField = () => {
    setClassificationCode(ClassificationCode.Bfs);
    setDocTitle('');
    setDocName('');
    setCategory(null);
  };

  const handleClassificationChange = (e) => {
    setDocName('');
    setClassificationCode(e.target.value);
  };

  const handleTitleChange = (e) => setDocTitle(e.target.value);

  const handleDocNumberChange = (number: string, valid: boolean) => {
    setDocName(number);
    setValidDocNumber(valid);
    setDocNumberExists(false);
  };

  const [addGuide, { loading }] = useMutation(ADD_NEW_GUIDE, {
    variables: {
      input: {
        docTitle,
        docName: classificationCode === ClassificationCode.Bvn ? `BVN ${docName}` : docName,
        categoryId: category?.id,
        classificationId: classification?.classificationId,
        classificationItemId: classification?.classificationItemId
      },
    },
    refetchQueries: [GET_CLASSIFICATIONS],
  });

  const handleSave = async () => {
    const result = await addGuide();
    if (result.data?.guideCreate.guideModel) {
      handleClose();
      onGuideCreated(result.data.guideCreate.guideModel.id);
    } else {
      if (result.data?.guideCreate.errors?.some((e) => e.code === 'GuideAlreadyExistsError')) {
        setValidDocNumber(false);
        setDocNumberExists(true);
      }
    }
  };

  const canSave = validDocNumber && docTitle.length > 0 && classification;
  return (
    <Dialog fullWidth={true} maxWidth={'sm'} open={true} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Opprett ny BFS anvisning/BVN blad</DialogTitle>
      <DialogContent>
        <Grid container direction="row">
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="choose between bfs And bvn"
                name="bfsAndbvn"
                value={classificationCode}
                onChange={handleClassificationChange}
                row
              >
                <FormControlLabel value={ClassificationCode.Bfs} control={<Radio />} label="BFS anvisning" />
                <FormControlLabel value={ClassificationCode.Bvn} control={<Radio />} label="BVN blad" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              label="Tittel"
              type="text"
              autoFocus
              value={docTitle}
              fullWidth
              required
              onChange={(e) => handleTitleChange(e)}
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: 11 }}>
            <DocNumberField
              value={docName}
              onChange={handleDocNumberChange}
              classificationCode={classificationCode}
              isValidClassification={classification !== undefined}
            />
          </Grid>
          <Grid item xs={12}>
            <ClassificationLabel value={docName} classificationCode={classificationCode} onChange={setClassification} />
          </Grid>
          <Grid item xs={12}>
            <CategorySelect value={category} onChange={setCategory} isBvn={classificationCode === ClassificationCode.Bvn} />
          </Grid>
          <Grid item xs={12}>
            <Alert severity="warning">
              Anvisningsnummeret kan ikke endres på et senere tidspunkt. Vennligst sjekk at anvisningen har riktig nummer.
            </Alert>
            {docNumberExists && <Alert severity="error">Anvisningsnummeret er allerede tatt.</Alert>}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {loading && <CircularProgress size={25} />}
        <Button onClick={handleClose}>Avbryt</Button>
        <Button onClick={handleSave} variant="contained" color="primary" disabled={!canSave || loading}>
          Opprett
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function DocNumberField({
  value,
  onChange,
  classificationCode,
  isValidClassification,
}: {
  value: string;
  onChange(value: string, valid: boolean): void;
  classificationCode: ClassificationCode;
  isValidClassification: boolean;
}) {
  const [errorText, setErrorText] = useState<string | undefined>(undefined);
  const { data } = useQuery(GET_GUIDES);

  const isLegalPattern = (val: string, allowIncomplete: boolean = true) => {
    var pattern = classificationCode === ClassificationCode.Bfs ? String.raw`\d\d\d\.\d\d\d` : String.raw`\d\d\.\d\d\d`;
    if (allowIncomplete) pattern = pattern.substring(0, val.length * 2);
    var re = new RegExp('^' + pattern + '$');
    return val.match(re) != null;
  };

  const isValidDocNumber = (number: string): boolean => isLegalPattern(number, false);

  const handleChange = (newValue: string) => {
    var val = newValue;
    if (!isLegalPattern(val)) return;
    if (val.length > value.length && val.length === (classificationCode === ClassificationCode.Bfs ? 3 : 2)) {
      val = val + '.';
    }

    setErrorText(undefined);
    onChange(val, isValidDocNumber(val) && !isDocNumberInUse(val));
  };

  const validateValue = () => {
    if (value.length === 0) setErrorText(undefined);
    else if (!isValidDocNumber(value)) setErrorText(`Må følge formatet: ${pattern}`);
    else if (!isValidClassification) setErrorText('Må plasseres på en synlig del av klassifikasjonssystemet');
    else setErrorText(undefined);
  };

  const isDocNumberInUse = (val): boolean | null | undefined => {
    const docName = classificationCode === ClassificationCode.Bfs ? val : 'BVN ' + val;
    return data?.guides && data.guides.some((x) => x!.docName === docName);
  };

  const pattern = classificationCode === ClassificationCode.Bfs ? '###.###' : '##.###';
  const inUseText: string | undefined = isValidDocNumber(value) && isDocNumberInUse(value) ? `Nummeret er allerede i bruk` : undefined;

  return (
    <TextField
      margin="dense"
      label="Nummer"
      type="text"
      value={value}
      fullWidth
      required
      InputProps={{
        inputProps: {
          placeholder: pattern,
          maxLength: pattern.length,
        },
      }}
      helperText={errorText ? errorText : inUseText}
      error={inUseText !== undefined || errorText !== undefined}
      onChange={(e) => handleChange(e.target.value)}
      onBlur={validateValue}
    />
  );
}
