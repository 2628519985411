import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  CircularProgress,
  Grid,
} from '@mui/material';
import { BackButton, ListLabel, StyledLink } from '../Components';
import { useQuery, useMutation } from '@apollo/client';
import {
  All_DEPARTMENTS,
  CREATE,
  UPDATE_NAME,
  ADD_UNIT,
  UPDATE_UNIT_NAME,
  DELETE_UNIT,
  DELETE,
  GET_CHANGES,
} from '../organization/organization.graphql';
import LoadingSpinner from '../LoadingSpinner';
import { ColWidths, TableCellHead } from '../components/TableComponents';
import { Add, Save, Delete } from '@mui/icons-material';
import { TextEditor } from '../subjectResources/Details';
import { SUBJECT_RESOURCES_BY_UNIT } from '../subjectResources/subjectResources.graphql';
import { Changes } from '../components/Changes';
import { Unpacked } from '../graphQLTypes/types';
import { GetDepartmentsQuery, UnitModel } from '../__generated__/graphql';
import { StyledPaper } from '../theme';

const PREFIX = 'OrganizationAdmin';

const classes = {
  deleteButton: `${PREFIX}-deleteButton`,
};

const StyledTable = styled(Table)({
  [`& .${classes.deleteButton}`]: {
    marginBottom: 10,
  },
});

export function Admin() {
  return (
    <>
      <Typography variant="h5" gutterBottom>
        <BackButton />
        Endre organisasjon
      </Typography>
      <Departments />
    </>
  );
}

function Departments() {
  const [selected, setSelected] = useState<string | undefined>(undefined);
  const [selectedUnitId, setSelectedUnitId] = useState<string | undefined>(undefined);
  const { loading, error, refetch, data } = useQuery(All_DEPARTMENTS, { fetchPolicy: 'cache-and-network' });

  const changeSelectedDepartment = (id: string | undefined) => {
    setSelectedUnitId(undefined);
    setSelected(id);
  };

  const selectedDepartment = data?.departments?.find((x) => x && x.id === selected);

  return (
    <>
      <StyledPaper>
        <Typography variant="h5" gutterBottom>
          Avdelinger
          <AddDepartmentButton setSelected={changeSelectedDepartment} onAdd={async () => await refetch()} />
        </Typography>
        {error && <Typography>{error.message}</Typography>}
        {loading && <LoadingSpinner />}
        {data?.departments && <DepartmentTable departments={data.departments} selected={selected} setSelected={changeSelectedDepartment} />}
      </StyledPaper>
      {selectedDepartment && (
        <DepartmentDetails
          department={selectedDepartment}
          selectedUnitId={selectedUnitId}
          setSelectedUnitId={setSelectedUnitId}
          close={() => setSelected(undefined)}
        />
      )}
    </>
  );
}

function AddDepartmentButton({ setSelected, onAdd }: { setSelected(d: string): void; onAdd(): Promise<any> }) {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [create, { loading }] = useMutation(CREATE, { variables: { input: { name } } });
  const close = () => {
    setName('');
    setOpen(false);
  };
  const submit = async () => {
    const { data } = await create();
    await onAdd();
    setSelected(data?.departmentAdd?.departmentModel?.id);
    close();
  };

  const title = 'Legg til ny avdeling';
  return (
    <>
      <IconButton onClick={() => setOpen(true)} title={title} size="large">
        <Add />
      </IconButton>
      <Dialog open={open} onClose={close}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <TextField margin="dense" label="Navn" type="text" autoFocus value={name} onChange={(ev) => setName(ev.target.value)} fullWidth />
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>Avbryt</Button>
          <Button
            disabled={loading}
            onClick={submit}
            variant="contained"
            startIcon={loading ? <CircularProgress size={15} color="secondary" /> : <Save />}
            color="primary">
            Legg til
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

type Department = Unpacked<GetDepartmentsQuery['departments']>;

function DepartmentTable({
  departments,
  selected,
  setSelected,
}: {
  departments: Department[];
  selected: string | undefined;
  setSelected(d: string): void;
}) {
  return (
    <StyledTable size="small">
      <ColWidths widths={[null, 80]}></ColWidths>
      <TableHead>
        <TableRow>
          <TableCellHead>Navn</TableCellHead>
          <TableCellHead align="right">Antall enheter</TableCellHead>
        </TableRow>
      </TableHead>
      <TableBody>
        {departments
          .filter((x) => x && !x.isDeleted)
          .map((department) => {
            if (!department) return null;
            return (
              <TableRow key={department.id} hover onClick={() => setSelected(department.id)} selected={department.id === selected}>
                <TableCell>{department.name}</TableCell>
                <TableCell align="right">{department.units?.filter((x) => !x?.isDeleted).length}</TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    </StyledTable>
  );
}

function DepartmentDetails({
  department,
  selectedUnitId,
  setSelectedUnitId,
  close,
}: {
  department: Department;
  selectedUnitId: string | undefined;
  setSelectedUnitId(id: string | undefined): void;
  close(): void;
}) {
  const [updateName] = useMutation(UPDATE_NAME);
  const [deleteDepartment] = useMutation(DELETE, { variables: { input: { id: department?.id } } });
  const changeName = async (name: string) => {
    await updateName({ variables: { input: { id: department?.id, name } } });
  };
  const doDelete = () => {
    deleteDepartment();
    close();
  };
  if (!department) return null;

  return (
    <Grid container>
      <Grid item xs={8}>
        <StyledPaper>
          <Typography variant="h5" gutterBottom>
            Valgt avdeling: {department.name}
          </Typography>
          <List dense>
            <ListItem>
              <ListLabel>Navn</ListLabel>
              <ListItemText>
                <TextEditor placeholder="Navn" value={department.name ?? ''} setValue={changeName} />
              </ListItemText>
            </ListItem>
          </List>
          {department.units?.filter((x) => !x?.isDeleted).length === 0 && (
            <Button className={classes.deleteButton} variant="contained" color="primary" onClick={doDelete} startIcon={<Delete />}>
              Slett avdeling
            </Button>
          )}
          <Typography variant="h6">
            Enheter
            <AddUnitButton departmentId={department.id} />
          </Typography>
          <UnitTable units={department.units as UnitModel[]} selected={selectedUnitId} setSelected={setSelectedUnitId} />
        </StyledPaper>
        {selectedUnitId && (
          <UnitDetails
            department={department}
            unit={department.units?.find((x) => x?.id === selectedUnitId)!}
            close={() => setSelectedUnitId(undefined)}
          />
        )}
      </Grid>
      <Grid item xs={4}>
        <ChangeLog departmentId={department.id} />
      </Grid>
    </Grid>
  );
}

function ChangeLog({ departmentId }: { departmentId: string }) {
  return <Changes id={departmentId} gqlQuery={GET_CHANGES} />;
}

function AddUnitButton({ departmentId }) {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [create, { loading }] = useMutation(ADD_UNIT, { variables: { input: { id: departmentId, name } } });
  const close = () => {
    setName('');
    setOpen(false);
  };
  const submit = () => {
    create();
    close();
  };

  const title = 'Legg til ny enhet';
  return (
    <>
      <IconButton onClick={() => setOpen(true)} title={title} size="large">
        <Add />
      </IconButton>
      <Dialog open={open} onClose={close}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <TextField margin="dense" label="Navn" type="text" autoFocus value={name} onChange={(ev) => setName(ev.target.value)} fullWidth />
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>Avbryt</Button>
          <Button
            disabled={loading}
            onClick={submit}
            variant="contained"
            startIcon={loading ? <CircularProgress size={15} color="secondary" /> : <Save />}
            color="primary">
            Legg til
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function UnitTable({
  units,
  selected,
  setSelected,
}: {
  units: UnitModel[] | null | undefined;
  selected: string | undefined;
  setSelected(d: string | undefined): void;
}) {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCellHead>Navn</TableCellHead>
        </TableRow>
      </TableHead>
      <TableBody>
        {units &&
          units
            .filter((x) => !x.isDeleted)
            .map((unit) => (
              <TableRow key={unit.id} hover onClick={() => setSelected(unit.id)} selected={unit.id === selected}>
                <TableCell>{unit.name}</TableCell>
              </TableRow>
            ))}
      </TableBody>
    </Table>
  );
}

function UnitDetails({ department, unit, close }: { department: Department; unit: UnitModel; close(): void }) {
  const [hasResources, setHasResources] = useState(false);
  const [updateName] = useMutation(UPDATE_UNIT_NAME);
  const [deleteUnit] = useMutation(DELETE_UNIT, { variables: { input: { id: department?.id, unitId: unit.id } } });
  const changeName = async (name: string) => {
    await updateName({ variables: { input: { id: department?.id, unitId: unit.id, name } } });
  };
  const doDelete = () => {
    deleteUnit();
    close();
  };
  return (
    <StyledPaper>
      <Typography variant="h5" gutterBottom>
        Valgt enhet: {unit.name}
      </Typography>
      <List dense>
        <ListItem>
          <ListLabel>Navn</ListLabel>
          <ListItemText>
            <TextEditor placeholder="Navn" value={unit.name ?? ''} setValue={changeName} />
          </ListItemText>
        </ListItem>
      </List>
      {!hasResources && (
        <Button className={classes.deleteButton} variant="contained" color="primary" onClick={doDelete} startIcon={<Delete />}>
          Slett enhet
        </Button>
      )}
      <Typography variant="h6">Fagressurser</Typography>
      <SubjectResources unitId={unit.id} setHasResources={setHasResources} />
    </StyledPaper>
  );
}

function SubjectResources({ unitId, setHasResources }: { unitId: string; setHasResources(f: boolean): void }) {
  const { loading, error, data } = useQuery(SUBJECT_RESOURCES_BY_UNIT, { variables: { unitId } });

  if (error) return <Typography>{error.message}</Typography>;
  if (loading || !data?.resources) return <LoadingSpinner />;
  setHasResources(data.resources.filter((x) => !x?.isDeleted).length > 0);
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCellHead>Navn</TableCellHead>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.resources.map((resource) => (
          <TableRow key={resource?.id}>
            <TableCell>
              <StyledLink to={`/subjectResource/${resource?.id}`}>{resource?.name}</StyledLink>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
