import styled from '@emotion/styled';
import React from 'react';
import { FC } from 'react';
import Dropzone, { Accept } from 'react-dropzone';
import { useDataSourceContext } from './DataSourceContext';
import { read, utils } from 'xlsx';

export const ExcelImport: FC = () => {
  const { isDraft, objectsToDataSource } = useDataSourceContext();

  const onDrop = async (newFiles: File[]) => {
    if (newFiles.length !== 1) return;

    const rows = await readFile(newFiles[0]);
    objectsToDataSource(rows);
  };

  return <FileChooser disabled={!isDraft} accept={{ 'application/vnd.ms-excel': ['.xlsx', '.xls'] }} onDrop={onDrop} />;
};

function readFile(file: File): Promise<unknown[]> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onabort = reject;
    reader.onload = function (e) {
      if (e.target && e.target.result) {
        const data = new Uint8Array(e.target.result as ArrayBufferLike);
        const workbook = read(data, { type: 'array' });
        if (workbook.SheetNames.length === 0) return [];
        const sheet = workbook.Sheets[workbook.SheetNames[0]];
        const obj = utils.sheet_to_json(sheet);
        resolve(obj);
      } else {
        reject();
      }
    };
    reader.readAsArrayBuffer(file);
  });
}

const StyledDropzone = styled.div`
  padding: 0 20px;
  border-width: 2px;
  border-radius: 8px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
`;

const FileChooser: FC<{ disabled?: boolean; accept?: Accept; onDrop: (newFiles: File[]) => void }> = ({ disabled, onDrop, accept }) => {
  return (
    <Dropzone disabled={disabled} onDrop={onDrop} accept={accept}>
      {({ getRootProps, getInputProps }) => (
        <StyledDropzone {...getRootProps()}>
          <input {...getInputProps()} />
          <p>Dra og slipp fil her, eller klikk for å velge</p>
        </StyledDropzone>
      )}
    </Dropzone>
  );
};
