import React from 'react';

import { Grid, Typography } from '@mui/material';
import { Alert, AlertTitle } from '@mui/material';
import { BackButton, StyledLink } from '../Components';
import { Changes } from './Changes';
import { TasksForGuide } from '../tasks/TasksForGuide';
import { GuideDetails } from './GuideDetails';
import Releases from './Releases';
import { Backlog } from '../backlog/BacklogForGuide';
import { GuideRevision } from './Revision';
import { Comments } from './Comments';
import { GET_GUIDE } from './guide.graphql';
import { useQuery } from '@apollo/client';
import LoadingSpinner from '../LoadingSpinner';
import { GuideBadges } from './GuideBadges';
import { GuideEdit } from './Edit';
import { UserFeedback } from './UserFeedback';
import { BookListForGuide } from '../bookAdmin/BookListForGuide';
import { useParams } from 'react-router-dom';
import { GetGuideQuery, GuideStatus } from '../__generated__/graphql';
import { Unpacked } from '../graphQLTypes/types';
import { GuideHearing } from '../hearings/GuideHearing';

export type Guide = NonNullable<GetGuideQuery['guide']>;

export function Details() {
  const { id } = useParams();
  const { loading, data, refetch } = useQuery(GET_GUIDE, { variables: { id } });

  if (loading || !data) return <LoadingSpinner />;
  if (!data.guide) return <div>Fant ikke anvisning</div>;
  const guide = data.guide;

  return (
    <div>
      <Grid container>
        <Grid item container>
          <Grid item xs={12}>
            <Typography variant="h5">
              <BackButton />
              {guide.docName} {guide.docTitle}
              <GuideBadges guide={guide} />
            </Typography>
          </Grid>
          <ReplacedByPanel guide={guide} />
        </Grid>
        <Grid item lg={8}>
          <Grid container>
            <Grid item xs={12}>
              <GuideDetails guide={guide} />
            </Grid>
            <Planning guide={guide} />
            <Edit guide={guide} />
            <Grid item xs={12}>
              <TasksForGuide guideId={guide.id} tasks={guide.tasks ?? []} onTaskAdded={refetch} />
            </Grid>
            <Grid item xs={12}>
              <Comments guideId={guide.id} comments={guide.comments ?? []} onUpdate={() => refetch()} />
            </Grid>
            {guide.hasUserFeedback && (
              <Grid item xs={12}>
                <UserFeedback guideId={guide.id} />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Grid item xs={12}>
            <Releases guide={guide} />
          </Grid>
          <Grid item xs={12}>
            <Changes guideId={guide.id} />
          </Grid>
          <Grid item xs={12}>
            <BookListForGuide guide={guide} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

function Edit({ guide }: { guide: Guide }) {
  if (guide.status === GuideStatus.Expired) return null;
  return (
    <Grid item xs={12}>
      <GuideEdit guide={guide} />
    </Grid>
  );
}

function Planning({ guide }: { guide: Guide }) {
  if (guide.status === GuideStatus.Expired) return null;

  return (
    <>
      <Grid item xs={12} lg={6}>
        <GuideRevision guide={guide} />
        <GuideHearing guide={guide} />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Backlog guide={guide} />
      </Grid>
    </>
  );
}

function ReplacedByPanel({ guide }: { guide: Guide }) {
  if (!guide.replacementGuides) return null;
  if (guide.status !== GuideStatus.Expired || guide.replacementGuides.length === 0) return null;

  return (
    <Alert severity="info">
      <AlertTitle>Erstattet</AlertTitle>
      <Typography display="inline">Denne anvisningen er erstattet av: </Typography>
      {guide.replacementGuides.map((guideAsLink, index) => {
        if (!guideAsLink) return null;
        return <GuideLink key={guideAsLink.id} guide={guideAsLink} withSeparator={index > 0} />;
      })}
    </Alert>
  );
}

type GuideAsLink = NonNullable<Unpacked<Guide['replacementGuides']>>;
function GuideLink({ guide, withSeparator }: { guide: GuideAsLink; withSeparator: boolean }) {
  return (
    <>
      {withSeparator && <span>, </span>}
      <Typography display="inline">
        <StyledLink to={guide.id}>
          {guide.docName} {guide.docTitle}
        </StyledLink>
      </Typography>
    </>
  );
}
