import React from 'react';
import { useMutation } from '@apollo/client';
import { Alert, AlertTitle, Button, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { FC } from 'react';
import { CREATE_NEW_GUIDE_CONTENT_VERSION, IMPORT_LAST_PUBLISHED_VERSION } from './content.graphql';
import LoadingSpinner from '../../LoadingSpinner';
import { CloudDownload, Delete, Edit, History, InfoOutlined, MoreVert, Update, Visibility } from '@mui/icons-material';
import { Stack } from '@mui/system';
import { GuideEditor } from './tiptap/GuideEditor';
import { useGuideEditor, useReadOnlyGuideEditor } from './tiptap/useGuideEditor';
import { StyledPaper } from '../../theme';
import { GuideChangeHistoryDialog, GuideChangeHistoryDialogProps, GuideChangeHistoryDialogResult } from './historicContent/GuideChangeHistoryDialog';
import { getTipTapJsonContent } from './tiptap/tiptapHelper';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'oidc-react';
import { getAwarenessState } from './hocuspocus/hocuspocusHelper';
import { HocuspocusConnectedUsers } from './hocuspocus/HocuspocusConnectedUsers';
import { HocuspocusConnectionStatus } from './hocuspocus/HocuspocusConnectionStatus';
import { HistoricContentReader } from './historicContent/HistoricContentReader';
import { BackButton } from '../../Components';
import { useModal } from '../../dialogs/useModal';
import { useGuideCmsContext } from './GuideCmsContext';
import { useUpdateNumberedNodesOnEditor } from './tiptap/numbering';
import { VersionCommentDialog, VersionCommentDialogProps, VersionCommentDialogDialogResult } from './dialogs/CreateNewVersionDialog';
import { formatDate } from '../../Formatters';
import { useConfig } from '../../env';
import { useCommentsContext } from './sidebar/comments/CommentsContext';

export const GuideCms: FC = () => {
  const { isEditing, guideId, version, refreshContent } = useGuideCmsContext();
  const navigate = useNavigate();
  const changeHistoryModal = useModal<GuideChangeHistoryDialogProps, GuideChangeHistoryDialogResult>({ data: { guideId } });
  const handleHistoricChangesClick = async () => {
    const payload = await changeHistoryModal.open();
    if (payload?.action === 'navigate') {
      navigate(`/guide-editor/${guideId}?version=${payload.version}`);
    } else if (payload?.action === 'restore') {
      if (version) {
        navigate(`/guide-editor/${guideId}`);
      } else {
        await refreshContent();
      }
    }
  };

  return (
    <>
      {changeHistoryModal.isOpen && <GuideChangeHistoryDialog {...changeHistoryModal} />}
      {isEditing ? (
        <GuideCmsEditable onHistoricChangesClick={handleHistoricChangesClick} />
      ) : (
        <GuideCmsReadOnly onHistoricChangesClick={handleHistoricChangesClick} />
      )}
    </>
  );
};

const GuideCmsEditable: FC<{ onHistoricChangesClick: () => void }> = ({ onHistoricChangesClick }) => {
  const { content, guideId, version, docName, docTitle, isLoading, editorContent, setIsEditing, refreshContent, setEditor, forceRefreshEditors } =
    useGuideCmsContext();
  const auth = useAuth();
  const { closeComments } = useCommentsContext();

  const { editor, hocuspocus } = useGuideEditor({
    guideId: guideId,
    onEditorCreated: (editor) => {
      setEditor(editor);
    },
  });
  const updateNumberedNodesOnEditor = useUpdateNumberedNodesOnEditor();

  const [
    importLastPublishedVersion,
    { loading: isImportingLastPublishedVersion, error: importLastPublishedVersionError, reset: resetImportLastPublishedVersionError },
  ] = useMutation(IMPORT_LAST_PUBLISHED_VERSION);
  const [createNewContentVersion, { loading: isCreatingNewContentVersion }] = useMutation(CREATE_NEW_GUIDE_CONTENT_VERSION);

  const onCancelClick = async () => {
    setIsEditing(false);
    closeComments();
  };

  const onCancelAndDiscardClick = async () => {
    const profile = auth.userData?.profile;
    if (hocuspocus.provider && profile) {
      const awareness = getAwarenessState(hocuspocus.provider);
      const otherUsers = awareness.getOtherUsers(profile);
      const confirmMessage = `
        Alt ulagret arbeid vil gå tapt.
        ${
          otherUsers.length > 0
            ? `Akkurat nå er det flere personer som jobber på denne anvisnigen: ${otherUsers.map((user) => user.name).join(', ')}.\n`
            : ''
        }
        Er du sikker på at du vil fortsette? 
      `;
      const shouldContinue = awareness.numberOfConnections === 1 || confirm(confirmMessage);
      if (shouldContinue) {
        if (editorContent && editor) {
          editor.commands.setContent(editorContent);
          updateNumberedNodesOnEditor(editor);
          forceRefreshEditors();
          closeComments();
        }
      }
    }
  };

  const onCreateNewVersionClick = async () => {
    try {
      const newContent = JSON.stringify(editor?.getJSON());
      const result = await versionCommentDialog.open();
      if (!result) {
        return;
      }
      await createNewContentVersion({ variables: { input: { id: guideId, content: newContent, comment: result.comment } } });
      await refreshContent();
      setIsEditing(false);
      closeComments();
    } catch (e) {
      console.error('Failed to create new version', e);
    }
  };

  const onImportLastPublishedVersionClick = async () => {
    try {
      const result = await importLastPublishedVersion({ variables: { input: { id: guideId } } });
      const lastPublishedVersion = result?.data?.guideImportLastPublishedVersion?.importLastPublishedVersionPayload?.cmsContent;
      if (!editor) return;
      editor.commands.setContent(getTipTapJsonContent(lastPublishedVersion ?? ''));
      updateNumberedNodesOnEditor(editor);
      forceRefreshEditors();
      closeComments();
    } catch (e) {
      console.error('Failed load content from Word\n\n', e);
    }
  };

  const versionCommentDialog = useModal<VersionCommentDialogProps, VersionCommentDialogDialogResult>({ data: {} });
  const changeHistoryModal = useModal<GuideChangeHistoryDialogProps, GuideChangeHistoryDialogResult>({ data: { guideId } });
  const openChangeHistoryModal = async () => {
    onCancelClick();
    onHistoricChangesClick();
  };

  return (
    <StyledPaper>
      {versionCommentDialog.isOpen && <VersionCommentDialog {...versionCommentDialog} />}
      {changeHistoryModal.isOpen && <GuideChangeHistoryDialog {...changeHistoryModal} />}
      <Stack gap={2}>
        {(!isLoading || !!editorContent) && (
          <Stack direction="row" flexWrap="wrap" alignItems="center">
            <Stack direction="row" gap={1} alignItems="center">
              <Typography variant="h5">
                <BackButton /> {docName} {docTitle} (v. {version ?? content?.version ?? 0})
              </Typography>
              {!version && content && (
                <Tooltip
                  title={
                    <>
                      Sist oppdatert av {content?.lastChangedBy?.name}
                      <br />
                      {formatDate(content?.lastChangedAt)}
                      {content?.comment && (
                        <>
                          <br />
                          <br />
                          {content?.comment}
                        </>
                      )}
                    </>
                  }>
                  <InfoOutlined color="info" />
                </Tooltip>
              )}
            </Stack>
          </Stack>
        )}
        {!isImportingLastPublishedVersion && importLastPublishedVersionError && (
          <Alert severity="error" onClose={() => resetImportLastPublishedVersionError()}>
            Klarte ikke laste innhold fra sist publiserte anvisning. Vennligst prøv igjen.
          </Alert>
        )}
        {hocuspocus.saveStatus.invalidSchemaError && (
          <Alert
            severity="error"
            action={
              <Button variant="contained" color="error" onClick={() => window.location.reload()}>
                Last siden på nytt
              </Button>
            }>
            <AlertTitle>Lagring feilet</AlertTitle>
            Du prøver å lagre en utdatert versjon av CMS dataformatet. Vennligst last siden på nytt for å fortsette på nyeste format.
          </Alert>
        )}
        {!version && (isLoading || isImportingLastPublishedVersion) && <LoadingSpinner />}
        {!version && !isLoading && !isImportingLastPublishedVersion && editor && (
          <GuideEditor
            guideId={guideId}
            editor={editor}
            menuComponent={
              <Stack direction="row" flexWrap="wrap">
                <Stack direction="row" gap={2} marginLeft="auto" alignItems="center">
                  <HocuspocusConnectedUsers users={hocuspocus.connectedUsers} />
                  <HocuspocusConnectionStatus connectionStatus={hocuspocus.connectionStatus} saveStatus={hocuspocus.saveStatus} />

                  <Button variant="outlined" disabled={isCreatingNewContentVersion} onClick={onCreateNewVersionClick} startIcon={<Update />}>
                    Oppdater...
                  </Button>
                  <Button variant="contained" onClick={onCancelClick}>
                    Lukk
                  </Button>
                  <ActionsMenu>
                    <PreviewMenuItem />
                    <MenuItem
                      onClick={() => {
                        openChangeHistoryModal();
                      }}>
                      <ListItemIcon>
                        <History fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Historiske endringer...</ListItemText>
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        onCancelAndDiscardClick();
                      }}>
                      <ListItemIcon>
                        <Delete fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Forkast siste endringer</ListItemText>
                    </MenuItem>
                    <MenuItem
                      disabled={isImportingLastPublishedVersion}
                      onClick={() => {
                        onImportLastPublishedVersionClick();
                      }}>
                      <ListItemIcon>
                        <CloudDownload fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Last inn sist publiserte anvisning</ListItemText>
                    </MenuItem>
                  </ActionsMenu>
                </Stack>
              </Stack>
            }
          />
        )}
      </Stack>
    </StyledPaper>
  );
};

const GuideCmsReadOnly: FC<{ onHistoricChangesClick: () => void }> = ({ onHistoricChangesClick }) => {
  const { content, guideId, version, docName, docTitle, isLoading, editorContent, setIsEditing, refreshContent, setEditor } = useGuideCmsContext();
  const { closeComments } = useCommentsContext();
  const { editor: readOnlyEditor } = useReadOnlyGuideEditor({
    content: editorContent,
    onEditorCreated: (editor) => {
      setEditor(editor);
    },
  });

  const onEditClick = async () => {
    setIsEditing(true);
    closeComments();
    await refreshContent();
  };

  const openChangeHistoryModal = async () => {
    onHistoricChangesClick();
  };

  return (
    <StyledPaper>
      <Stack gap={2}>
        {(!isLoading || !!editorContent) && (
          <Stack direction="row" flexWrap="wrap" alignItems="center">
            <Stack direction="row" gap={1} alignItems="center">
              <Typography variant="h5">
                <BackButton /> {docName} {docTitle} (v. {version ?? content?.version ?? 0})
              </Typography>
              {!version && content && (
                <Tooltip
                  title={
                    <>
                      Sist oppdatert av {content?.lastChangedBy?.name}
                      <br />
                      {formatDate(content?.lastChangedAt)}
                      {content?.comment && (
                        <>
                          <br />
                          <br />
                          {content?.comment}
                        </>
                      )}
                    </>
                  }>
                  <InfoOutlined color="info" />
                </Tooltip>
              )}
            </Stack>
            <Stack direction="row" gap={2} marginLeft="auto" alignItems="center">
              {!version && (
                <>
                  <Button variant="contained" onClick={onEditClick} startIcon={<Edit />}>
                    Endre
                  </Button>
                </>
              )}
              <ActionsMenu>
                <PreviewMenuItem />

                <MenuItem
                  onClick={() => {
                    openChangeHistoryModal();
                  }}>
                  <ListItemIcon>
                    <History fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Historiske endringer...</ListItemText>
                </MenuItem>
              </ActionsMenu>
            </Stack>
          </Stack>
        )}
        {version && <HistoricContentReader version={version} />}
        {!version && isLoading && <LoadingSpinner />}
        {!version && !isLoading && readOnlyEditor && <GuideEditor guideId={guideId} editor={readOnlyEditor} />}
      </Stack>
    </StyledPaper>
  );
};

const ActionsMenu = ({ children }: { children: React.ReactNode }) => {
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnchorEl);
  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setMenuAnchorEl(null);
  };
  return (
    <div>
      <IconButton onClick={openMenu}>
        <MoreVert />
      </IconButton>
      <Menu open={isMenuOpen} onClick={closeMenu} onClose={closeMenu} anchorEl={menuAnchorEl} disableScrollLock>
        {children}
      </Menu>
    </div>
  );
};

const PreviewMenuItem: FC = () => {
  const config = useConfig();
  const { docId } = useGuideCmsContext();
  const previewUrl = `${config?.bksUrl}/dokument/${docId}?version=preview`;

  return (
    <MenuItem href={previewUrl} component="a" target="_blank">
      <ListItemIcon>
        <Visibility />
      </ListItemIcon>
      <ListItemText>Forhåndsvisning</ListItemText>
    </MenuItem>
  );
};
