import React, { FC } from 'react';
import { Editor } from '@tiptap/react';
import { LocalLibrary } from '@mui/icons-material';
import { MenuButton, useRichTextEditorContext } from 'mui-tiptap';
import { useModal } from '../../../../../dialogs/useModal';
import { StandardLinkDialog, StandardLinkDialogProps, StandardLinkDialogResult } from '../../../dialogs/StandardLinkDialog';

export const AddStandardLinkToolbarButton: FC = () => {
  const modal = useModal<StandardLinkDialogProps, StandardLinkDialogResult>({ data: {} });
  const editor = useRichTextEditorContext();
  const handleOnClick = async () => {
    const payload = await modal.open();
    if (payload) {
      addGuideLink(editor, payload);
    }
  };
  if (!editor) return null;
  return (
    <>
      {modal.isOpen && <StandardLinkDialog {...modal} />}
      <MenuButton disabled={!editor?.isEditable} IconComponent={LocalLibrary} tooltipLabel="Lenke til standard" onClick={handleOnClick}></MenuButton>
    </>
  );
};

const addGuideLink = (editor: Editor | null, payload: StandardLinkDialogResult) => {
  editor
    ?.chain()
    .focus()
    .insertContent({
      type: 'standardLink',
      attrs: {
        standardId: payload.id,
        standardName: payload.name.split(':')[0],
      },
    })
    .run();
};
