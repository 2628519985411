import React from 'react';
import Grid from '@mui/material/Grid';
import { SET_AUTHORS, SET_PROFESSIONAL_QUALITY_ASSURERS, SET_RESPONSIBLE } from './revisions.graphql';
import { useMutation, useQuery } from '@apollo/client';
import { Typography, FormControl, InputLabel, ListItem } from '@mui/material';
import { UserSelector } from '../users/UserSelector';
import { All_SUBJECT_RESOURCES } from '../subjectResources/subjectResources.graphql';
import { revisionRoleToDisplayText } from './RevisionModel';
import { ResourceSelect } from '../subjectResources/ResourceSelect';
import { Revision, User } from './Details';
import { RevisionRoles } from '../__generated__/graphql';
import { StyledPaper } from '../theme';

export function Roles({ revision, users, history, validate }: { revision: Revision; users: User[]; history: boolean; validate?: boolean }) {
  const [setAuthors] = useMutation(SET_AUTHORS);
  const [setProfessinalQualityAssurer] = useMutation(SET_PROFESSIONAL_QUALITY_ASSURERS);
  const updateAuthors = (newAuthors: string[]) => setAuthors({ variables: { input: { id: revision?.id, subjectResourceIds: newAuthors } } });
  const updateProfessionalQulityAssurer = (newAssurers: string[]) =>
    setProfessinalQualityAssurer({ variables: { input: { id: revision?.id, subjectResourceIds: newAssurers } } });
  return (
    <StyledPaper>
      <Typography variant="h5" component="h3">
        Roller
      </Typography>
      <Grid container>
        <Grid item xs={12}>
          <InternalRole history={history} revision={revision} role={RevisionRoles.ProjectLead} users={users} required={validate} />
        </Grid>
        <Grid item xs={12}>
          <InternalRole revision={revision} role={RevisionRoles.InteralQualityAssurer} users={users} history={history} />
        </Grid>
        <Grid item xs={12}>
          <InternalRole revision={revision} role={RevisionRoles.PeerReviewer} users={users} history={history} />
        </Grid>
        <Grid item xs={12}>
          <ExternalRole role="FF" selected={revision?.authors?.map((x) => x?.id)} selectedChanged={updateAuthors} history={history} />
        </Grid>
        <Grid item xs={12}>
          <ExternalRole
            role="FKS"
            selected={revision?.professionQualityAssurers?.map((x) => x?.id)}
            selectedChanged={updateProfessionalQulityAssurer}
            history={history}
          />
        </Grid>
        <Grid item xs={12}>
          <InternalRole revision={revision} role={RevisionRoles.Dac} users={users} history={history} />
        </Grid>
        <Grid item xs={12}>
          <InternalRole revision={revision} role={RevisionRoles.PublishingEditor} users={users} history={history} />
        </Grid>
      </Grid>
    </StyledPaper>
  );
}

function ExternalRole({
  role,
  selected,
  selectedChanged,
  history,
}: {
  role: string;
  selected: string[] | undefined;
  selectedChanged(ids: string[]): void;
  history: Boolean;
}) {
  const isSet = selected && selected.length > 0;
  const { data } = useQuery(All_SUBJECT_RESOURCES);
  const personName =
    data &&
    data.resources &&
    data.resources
      .filter((y) => selected?.includes(y?.id))
      .map((x) => x?.name)
      .toString();
  return (
    <>
      {history ? (
        <ListItem sx={{ paddingTop: 1, paddingBottom: 1 }}>
          <div style={{ flex: 0.35 }}>
            <Typography variant="subtitle1">{role + ':'} </Typography>
          </div>
          <div style={{ flex: 0.65 }}>{personName}</div>
        </ListItem>
      ) : (
        <Grid container sx={{ margin: (theme) => theme.spacing(1), width: '100%' }}>
          <Grid item xs={isSet ? 12 : 1}>
            <Typography
              sx={isSet ? { fontSize: 12, marginLeft: 2 } : { fontSize: 16, paddingTop: 1, paddingBottom: 1, marginLeft: 2 }}
              variant="body2"
              color="textSecondary">
              {role}
            </Typography>
          </Grid>
          <Grid item xs={isSet ? 12 : 11}>
            <ResourceSelect selected={selected ?? []} onUpdated={selectedChanged} />
          </Grid>
        </Grid>
      )}
    </>
  );
}

function InternalRole({
  revision,
  role,
  users,
  history,
  required,
}: {
  revision: Revision;
  role: RevisionRoles;
  users: User[];
  history: Boolean;
  required?: boolean;
}) {
  const [setResponsible] = useMutation(SET_RESPONSIBLE);
  const updateResponsible = (responsibleSubjectId: string) =>
    setResponsible({ variables: { input: { id: revision?.id, role, responsibleSubjectId } } });
  return history ? (
    <ReadOnly revision={revision} role={role} />
  ) : (
    <SimpleUserSelector revision={revision} role={role} users={users} onSelected={(value) => updateResponsible(value)} required={required} />
  );
}

function SimpleUserSelector({
  revision,
  users,
  role,
  onSelected,
  required,
}: {
  revision: Revision;
  users: User[];
  role: RevisionRoles;
  onSelected(selectedUserId: string): void;
  required?: boolean;
}) {
  const title = revisionRoleToDisplayText(role);
  const userValue = revision?.roles?.find((x) => x?.role === role)?.user?.subjectId ?? '';
  const error = userValue === '' && required;

  return (
    <form style={{ display: 'flex', flexWrap: 'wrap' }} autoComplete="off">
      <FormControl error={error} sx={{ margin: (theme) => theme.spacing(1), width: '100%' }}>
        <InputLabel htmlFor={role}>{title}</InputLabel>
        <UserSelector userId={userValue} users={users} onChange={onSelected} />
      </FormControl>
    </form>
  );
}

function ReadOnly({ revision, role }: { revision: Revision; role: RevisionRoles }) {
  const roleTitle = revisionRoleToDisplayText(role);
  const userName = revision?.roles?.find((x) => x?.role === role)?.user?.name ?? '';

  return (
    <ListItem sx={{ paddingTop: 1, paddingBottom: 1 }}>
      <div style={{ flex: 0.35 }}>
        <Typography variant="subtitle1">{roleTitle + ':'} </Typography>
      </div>
      <div style={{ flex: 0.65 }}>{userName}</div>
    </ListItem>
  );
}
