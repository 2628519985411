import { Checkbox, FormControlLabel, Grid, Icon, List, ListItem, ListItemText, ListSubheader, MenuItem, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { formatDate } from '../Formatters';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import { revisionTypeToDisplayText } from './RevisionModel';
import { useMutation } from '@apollo/client';
import { UPDATE_TASK, UPDATE_TYPE } from './revisions.graphql';
import { Revision } from './Details';
import { ChecklistTask, RevisionType } from '../__generated__/graphql';
import { StyledPaper } from '../theme';
import { StyledAnchor } from '../Components';

export function Checklist({ revision, history }: { revision: Revision; history: boolean }) {
  const [updateType] = useMutation(UPDATE_TYPE);
  const typeOptions = getRevisionTypeOptions();

  const renderItems = (revision: Revision) => {
    if (!revision?.checklist) {
      return null;
    }
    const { milestones } = revision.checklist;
    const updatedMilestones = updateMilestoneNumbers(milestones);
    function updateMilestoneNumbers(milestones: ({ number: number } | null)[] | undefined | null) {
      // Create a deep copy of the milestones array
      const sortedMilestones = JSON.parse(JSON.stringify(milestones)).sort((a: { number: number }, b: { number: number }) => a.number - b.number);
      for (let i = 0; i < sortedMilestones.length; i++) {
        sortedMilestones[i].number = i + 1;
      }
      return sortedMilestones;
    }

    return updatedMilestones.map((x) => {
      return (
        <Grid key={`grid-${x.name}`} item xs={12}>
          <List
            key={x.name}
            dense
            subheader={
              <ListSubheader sx={{ backgroundColor: 'rgba(255,255,255,0.9)' }}>
                {x.number} - {x.name} ({x.completedTasks}/{x.numberOfTasks})
              </ListSubheader>
            }>
            {x.tasks.map((y) => {
              return <TaskItem key={y.id} revisionId={revision.id} history={history} task={y} />;
            })}
          </List>
        </Grid>
      );
    });
  };

  const handleChangeType = (event) => {
    let value = event.target.value;
    updateType({ variables: { input: { id: revision?.id, type: value } } });
  };

  const getTypeRevisionOnNorwegianText = (revisionType: RevisionType) => {
    return typeOptions
      .filter((item) => item.value === revisionType)
      .map((option) => option.label)
      .toString();
  };

  const checklist = revision?.checklist;
  return (
    <StyledPaper>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="h5" component="h3">
            Sjekkliste
          </Typography>
          <Grid container alignItems="center">
            <Typography variant="caption">Versjon {checklist?.version}</Typography>
            {checklist?.latestVersion !== checklist?.version && (
              <>
                <Icon color="error" sx={{ fontSize: '1em', marginLeft: 15, marginRight: 5 }}>
                  warning
                </Icon>
                <Typography variant="caption">
                  <StyledAnchor href="https://byggforsk.atlassian.net/wiki/spaces/FOU/pages/61276291" target="_blank" rel="noopener noreferrer">
                    Ny versjon {checklist?.latestVersion} foreligger
                  </StyledAnchor>
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
        <Grid item>
          {history ? (
            'Type revisjon:' + revisionTypeToDisplayText(revision?.type)
          ) : (
            <TextField select label="Type sjekkliste" value={revision?.type} onChange={handleChangeType}>
              {typeOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Grid>
      </Grid>
      <List>
        <Grid container>{renderItems(revision)}</Grid>
      </List>
    </StyledPaper>
  );
}

function TaskItem({ revisionId, task, history }: { revisionId: string; task: ChecklistTask; history: boolean }) {
  const [checked, setChecked] = useState(task.completed);
  const [notRelevant, setNotRelevant] = useState(task.notRelevant);

  const [updateTask] = useMutation(UPDATE_TASK);
  const doUpdateTask = (done: boolean, irrelevant: boolean = false) =>
    updateTask({ variables: { input: { id: revisionId, taskId: task.id, done, notRelevant: irrelevant } } });

  const handleToggle = async () => {
    const val = !checked;
    setChecked(val);
    await doUpdateTask(val);
  };

  const handleToggleNotRelevant = async () => {
    const val = !notRelevant;
    setChecked(val);
    setNotRelevant(val);
    await doUpdateTask(val, val);
  };

  const setTaskIcons = (irrelevant: boolean) => {
    return irrelevant ? <NotInterestedIcon color="disabled" /> : <CheckBoxIcon color="disabled" />;
  };

  const setIrrelevantTaskIcons = (irrelevant: boolean) => {
    return irrelevant ? <CheckBoxIcon color="disabled" /> : <CropSquareIcon color="disabled" />;
  };

  return (
    <Grid container justifyContent="space-between" wrap="nowrap">
      <ListItem key={task.id} sx={{ paddingTop: 1, paddingBottom: 1 }}>
        <FormControlLabel
          control={
            history ? (
              setTaskIcons(notRelevant)
            ) : (
              <Checkbox
                color="primary"
                onChange={handleToggle}
                checked={checked && !notRelevant}
                indeterminate={notRelevant}
                disabled={notRelevant}
              />
            )
          }
          label={''}
        />
        <ListItemText
          primary={
            <Typography variant="subtitle1" color={notRelevant ? 'textSecondary' : 'initial'}>
              {task.name}
            </Typography>
          }
          secondary={task.completed ? `${formatDate(task.completedAt)} - ${task.completedBy}` : ''}
        />
      </ListItem>
      <FormControlLabel
        control={
          history ? setIrrelevantTaskIcons(notRelevant) : <Checkbox onChange={handleToggleNotRelevant} checked={notRelevant} color="primary" />
        }
        label={
          <Typography variant="body2" color="textSecondary" noWrap>
            Ikke relevant
          </Typography>
        }
      />
    </Grid>
  );
}

function getRevisionTypeOptions(): { label: string | null; value: RevisionType }[] {
  return Object.keys(RevisionType)
    .filter((x) => x !== RevisionType.Correction)
    .map((x) => {
      const item = RevisionType[x as keyof typeof RevisionType];
      return { value: item, label: revisionTypeToDisplayText(item) };
    });
}
