import { FC, useMemo } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Typography,
  List,
  ListItemText,
  ListItemButton,
  Stack,
} from '@mui/material';
import React from 'react';
import { Modal } from '../../../dialogs/useModal';
import { getNodesOfType, getText } from '../tiptap/tiptapHelper';
import { NumberedNodeType, getTypeDescription, useNumberedNodes } from '../tiptap/numbering';
import { capitalizeFirstLetter } from '../../../utils/string';
import { Extension, GridOn, Image, Toc } from '@mui/icons-material';
import { useGuideCmsContext } from '../GuideCmsContext';

export interface InternalLinksDialogResult {
  id: string;
  type: string;
}

export interface InternalLinksDialogProps {}

export const InternalLinksDialog: FC<Modal<InternalLinksDialogProps, InternalLinksDialogResult>> = (modal) => {
  const { close } = modal;
  const { editor } = useGuideCmsContext();
  const numberedNodes = useNumberedNodes();
  const handleOnClose = () => {
    close();
  };
  const handleOnInsert = (type: NumberedNodeType, id: string) => {
    close({ type, id });
  };
  const editorContent = useMemo(() => editor?.getJSON(), []);

  const figures = useMemo(() => (editorContent ? getNodesOfType(editorContent, ['imageFigure', 'chartFigure']) : []), []);
  const tables = useMemo(() => (editorContent ? getNodesOfType(editorContent, 'tableFigure') : []), []);
  const headings = useMemo(() => (editorContent ? getNodesOfType(editorContent, 'heading') : []), []);
  const apps = useMemo(() => (editorContent ? getNodesOfType(editorContent, 'app') : []), []);
  return (
    <Dialog open={true} onClose={handleOnClose} maxWidth="lg" fullWidth={true}>
      <DialogTitle>Legg til intern lenke</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={3}>
            <Stack direction="row" gap={1} alignItems="center">
              <Toc />
              <Typography variant="h6" component="h3">
                Overskrifter
              </Typography>
            </Stack>
            <List dense>
              {headings.map((heading) => (
                <ListItemButton
                  data-header-level={heading.attrs!.level}
                  key={heading.attrs!.id}
                  onClick={() => handleOnInsert('heading', heading.attrs!.id)}>
                  <ListItemText secondary={getText(heading)}>
                    {capitalizeFirstLetter(getTypeDescription('heading'))} {numberedNodes.get(heading.attrs!.id)?.numbering}{' '}
                  </ListItemText>
                </ListItemButton>
              ))}
            </List>
          </Grid>
          <Grid item xs={3}>
            <Stack direction="row" gap={1} alignItems="center">
              <Image />
              <Typography variant="h6" component="h3">
                Figurer
              </Typography>
            </Stack>
            <List dense>
              {figures.map((figure) => (
                <ListItemButton
                  data-header-level={figure.attrs!.level}
                  key={figure.attrs!.id}
                  onClick={() => handleOnInsert('imageFigure', figure.attrs!.id)}>
                  <ListItemText>
                    {capitalizeFirstLetter(getTypeDescription('imageFigure'))} {numberedNodes.get(figure.attrs!.id)?.numbering}
                  </ListItemText>
                </ListItemButton>
              ))}
            </List>
          </Grid>
          <Grid item xs={3}>
            <Stack direction="row" gap={1} alignItems="center">
              <GridOn />
              <Typography variant="h6" component="h3">
                Tabeller
              </Typography>
            </Stack>
            <List dense>
              {tables.map((table) => (
                <ListItemButton
                  data-header-level={table.attrs!.level}
                  key={table.attrs!.id}
                  onClick={() => handleOnInsert('tableFigure', table.attrs!.id)}>
                  <ListItemText>
                    {capitalizeFirstLetter(getTypeDescription('tableFigure'))} {numberedNodes.get(table.attrs!.id)?.numbering}
                  </ListItemText>
                </ListItemButton>
              ))}
            </List>
          </Grid>
          <Grid item xs={3}>
            <Stack direction="row" gap={1} alignItems="center">
              <Extension />
              <Typography variant="h6" component="h3">
                Applikasjoner
              </Typography>
            </Stack>
            <List dense>
              {apps.map((app) => (
                <ListItemButton data-header-level={app.attrs!.level} key={app.attrs!.id} onClick={() => handleOnInsert('app', app.attrs!.id)}>
                  <ListItemText>
                    {capitalizeFirstLetter(getTypeDescription('app'))} {numberedNodes.get(app.attrs!.id)?.numbering}
                  </ListItemText>
                </ListItemButton>
              ))}
            </List>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnClose}>Avbryt</Button>
      </DialogActions>
    </Dialog>
  );
};
