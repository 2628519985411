import React from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { BacklogItem, CriteriaType } from './types';
import { addRank } from './BacklogModel';

interface ChartProps {
  items: BacklogItem[];
  currentItem: BacklogItem;
  ignoreCriteria: CriteriaType[];
}


export function Chart(props: ChartProps) {
  let { items, currentItem, ignoreCriteria } = props;
  let activeIndex = items.indexOf(currentItem);

  const rankItems = addRank(items, ignoreCriteria);

  return (
    <ResponsiveContainer width="100%" height={250}>
      <BarChart data={rankItems.map((x) => ({ ...x, rank: x.rank.toFixed(2) }))} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
        <Bar dataKey="rank">
          {rankItems.map((entry, index) => (
            <Cell stroke={index === activeIndex ? '#ff8888' : '#287891'} strokeWidth={index === activeIndex ? 3 : 1} key={`cell-${index}`} />
          ))}
        </Bar>
        <XAxis dataKey="docName" tick={false} />
        <YAxis type="number" domain={[0, 300]} />
        <Tooltip />
      </BarChart>
    </ResponsiveContainer>
  );
}
