import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { StyledLink, PreWrap, LimitedTypography, SortBy } from '../Components';
import { parse } from 'qs';
import { ExpandMore, Gavel, LibraryBooks, LocalLibrary, PersonPin } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Divider,
  Table,
  TableHead,
  TableCell,
  Tabs,
  Tab,
  TableRow,
  TableBody,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Switch,
  Stack,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from '@mui/material';
import { formatDate } from '../Formatters';
import { TaskActions } from './TaskActions';
import { useQuery } from '@apollo/client';
import { GET_ALL } from './task.graphql';
import LoadingSpinner from '../LoadingSpinner';
import { Unpacked } from '../graphQLTypes/types';
import { GetTasksQuery, GuideRevisionNoticeTypes } from '../__generated__/graphql';
import { useAuth } from 'oidc-react';
import { StyledPaper } from '../theme';

type Task = Unpacked<GetTasksQuery['tasks']>;

export function Overview() {
  const location = useLocation();
  const showOnlyMineFromParam = parse(location.search.substr(1)).mine === '1';
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [showOnlyMine, setShowOnlyMine] = useState(showOnlyMineFromParam);
  const [taskType, setTaskType] = useState<GuideRevisionNoticeTypes | false>(false);

  const { data } = useQuery(GET_ALL, { fetchPolicy: 'cache-and-network' });

  useEffect(() => {
    localStorage.setItem('lastRead', new Date().toISOString());
  }, []);

  const tasks = data?.tasks?.filter((x) => x?.guides && x.guides.length > 0 && (!taskType || x.type === taskType)) ?? [];

  return (
    <StyledPaper>
      <Typography variant="h5">Oppgaver</Typography>
      <Stack direction="row" spacing={2}>
        <FormControlLabel control={<Switch onChange={(e) => setShowOnlyMine(e.target.checked)} checked={showOnlyMine} />} label="Mine" />
        <FormControl sx={{ minWidth: 270 }}>
          <InputLabel id="task-type-label">Oppgavetype</InputLabel>
          <Select
            labelId="task-type-label"
            value={taskType ? taskType : ''}
            label="Oppgavetype"
            placeholder="Alle"
            onChange={(e) => (e.target.value !== '' ? setTaskType(e.target.value as GuideRevisionNoticeTypes) : setTaskType(false))}
            SelectDisplayProps={{
              style: { display: 'flex', alignItems: 'center' },
            }}>
            <MenuItem sx={{ color: 'lightgrey' }}>
              <em>Alle</em>
            </MenuItem>
            {Object.keys(GuideRevisionNoticeTypes)
              .map((key) => GuideRevisionNoticeTypes[key as keyof typeof GuideRevisionNoticeTypes])
              .map((val) => (
                <MenuItem key={val} value={val}>
                  <ListItemIcon sx={{ minWidth: 36 }}>
                    <TaskIcon type={val} />
                  </ListItemIcon>
                  <ListItemText sx={{ my: 0 }}>
                    <TaskLabel type={val} />
                  </ListItemText>
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Stack>
      <Tabs value={selectedTabIndex} onChange={(e, value) => setSelectedTabIndex(value)}>
        <Tab label="Åpne" />
        <Tab label="Lukkede" />
      </Tabs>
      {!tasks && <LoadingSpinner />}
      {tasks && selectedTabIndex === 0 && <TaskList tasks={tasks} showOnlyMine={showOnlyMine} />}
      {tasks && selectedTabIndex === 1 && <TaskList tasks={tasks} completed showOnlyMine={showOnlyMine} />}
    </StyledPaper>
  );
}

export function TaskIcon({ type }: { type: GuideRevisionNoticeTypes }) {
  switch (type) {
    case GuideRevisionNoticeTypes.Paragraph:
      return <Gavel />;
    case GuideRevisionNoticeTypes.Standard:
      return <LocalLibrary />;
    case GuideRevisionNoticeTypes.Guide:
      return <LibraryBooks />;
    case GuideRevisionNoticeTypes.GuideFeedback:
      return <PersonPin titleAccess="Tilbakemelding fra bruker" />;

    default:
      return null;
  }
}

function TaskLabel({ type }: { type: GuideRevisionNoticeTypes }) {
  switch (type) {
    case GuideRevisionNoticeTypes.Paragraph:
      return 'Paragraf';
    case GuideRevisionNoticeTypes.Standard:
      return 'Standard';
    case GuideRevisionNoticeTypes.Guide:
      return 'Anvisning';
    case GuideRevisionNoticeTypes.GuideFeedback:
      return 'Tilbakemelding fra bruker';

    default:
      return null;
  }
}

function TaskList({ tasks, showOnlyMine, completed = false }: { tasks: Task[]; completed?: boolean; showOnlyMine: boolean }) {
  const { id } = useParams();
  const auth = useAuth();
  const userId = auth.userData?.profile.sub;
  if (!tasks) return null;

  const hasItems = (task: Task): boolean => task?.guides?.some((g) => g?.completed === completed && (!showOnlyMine || g.ownerId === userId)) ?? false;

  return (
    <div style={{ flexGrow: 1 }}>
      {tasks.filter(hasItems).map((t) => (
        <TaskAccordion key={t?.id} task={t} defaultExpanded={t?.id === id}>
          {completed ? <ClosedItems task={t} showOnlyMine={showOnlyMine} /> : <OpenItems task={t} showOnlyMine={showOnlyMine} />}
        </TaskAccordion>
      ))}
    </div>
  );
}

function TaskAccordion({ task, children, defaultExpanded }: { task: Task; children: any; defaultExpanded: boolean }) {
  if (!task) return null;
  return (
    <Accordion sx={{ margin: 0, padding: 0 }} key={task.id} defaultExpanded={defaultExpanded}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Grid container direction="row">
          <Grid sx={{ verticalAlign: 'middle', display: 'inline-flex', alignItems: 'start', paddingRight: '0.5em', whiteSpace: 'pre' }}>
            <ListItemIcon>
              <TaskIcon type={task.type} />
            </ListItemIcon>
            <span>{task.sourceDescription}</span>
          </Grid>
          <Grid sx={{ flexGrow: 1 }}>
            <LimitedTypography maxWidth={250}>{task.title}</LimitedTypography>
          </Grid>
          <Grid sx={{ paddingLeft: '0.5em' }}>
            <Typography sx={{ fontSize: (theme) => theme.typography.pxToRem(15), color: (theme) => theme.palette.text.secondary }}>
              <span style={{ whiteSpace: 'nowrap' }}>
                {task.createdByName} {formatDate(task.createdAt)}
              </span>
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={{ paddingTop: 0, paddingBottom: 0, background: 'rgba(0,0,0,0.02)' }}>
        <Grid container direction="column">
          <Grid container direction="row" wrap="nowrap">
            <ListItem key={task.id}>
              <ListItemText primary={<PreWrap variant="body1">{task.text}</PreWrap>} />
            </ListItem>
          </Grid>
          <Divider />
          <Grid item>{children}</Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

function ClosedItems({ task, showOnlyMine }: { task: Task; showOnlyMine: boolean }) {
  const auth = useAuth();
  const userId = auth.userData?.profile.sub;

  if (!task) return null;
  return (
    <Table padding="none">
      <TableHead>
        <TableRow sx={{ height: 40 }}>
          <TableCell>Anvisning</TableCell>
          <TableCell>Kommentar ved lukking</TableCell>
          <TableCell>Lukket av</TableCell>
          <TableCell>Tidspunkt</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {task.guides &&
          task.guides
            .filter((x) => x?.completed && (!showOnlyMine || x.ownerId === userId))
            .sort(guideComparerFn)
            .map((guide) => (
              <TableRow key={`task-${guide?.guideRevisionId}-${task.id}`} sx={{ height: 40 }}>
                <TableCell>{<GuideLink guide={guide} />}</TableCell>
                <TableCell>{guide?.completedComment}</TableCell>
                <TableCell>{guide?.completedByName}</TableCell>
                <TableCell>{formatDate(guide?.completedAt)}</TableCell>
              </TableRow>
            ))}
      </TableBody>
    </Table>
  );
}

function OpenItems({ task, showOnlyMine }: { task: Task; showOnlyMine: boolean }) {
  const auth = useAuth();
  const userId = auth.userData?.profile.sub;

  if (!task) return null;
  return (
    <List dense={true}>
      <ListSubheader sx={{ backgroundColor: 'inherit' }}>Anvisninger</ListSubheader>
      {task.guides &&
        task.guides
          .filter((x) => !x?.completed && (!showOnlyMine || x?.ownerId === userId))
          .sort(guideComparerFn)
          .map((guide) => (
            <ListItem key={`task-${guide?.guideRevisionId}-${task.id}`}>
              <ListItemText style={{ paddingRight: 150 }} primary={<GuideLink guide={guide} />} secondary={`Ansvarlig ${guide?.ownerName}`} />
              <ListItemSecondaryAction>{!guide?.completed && <TaskActions task={task} guideId={guide?.guideRevisionId} />}</ListItemSecondaryAction>
            </ListItem>
          ))}
    </List>
  );
}

function GuideLink({ guide }: { guide: any }) {
  return (
    <StyledLink to={`/guide/${guide.guideRevisionId}`}>
      {guide.name} {guide.title}
    </StyledLink>
  );
}

const guideComparerFn = SortBy('name', true, function (a) {
  return a.toUpperCase();
});
