import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { useQuery, useMutation } from '@apollo/client';
import {
  Typography,
  List,
  ListItem,
  TextField,
  ListItemText,
  Checkbox,
  Grid,
  Button,
  TableRow,
  TableBody,
  Table,
  TableHead,
  TableCell,
  FormControlLabel,
  Switch,
  IconButton,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import LoadingSpinner from '../LoadingSpinner';
import {
  SUBJECT_RESOURCE,
  UPDATE_NAME,
  UPDATE_INTERNAL,
  UPDATE_HAS_BEEN_INTERNAL,
  GET_CHANGES,
  GET_REVISIONS,
  GET_GUIDES,
  DELETE,
  UPDATE_UNIT,
  UPDATE_NOTES,
} from './subjectResources.graphql';
import { EditableTextField } from '../components/EditableTextField';
import { ListLabel, StyledLink } from '../Components';
import { formatDate, formatDateOnly } from '../Formatters';
import { TableCellHead, TableCellEllipsis, TableCellIcon, ColWidths } from '../components/TableComponents';
import { GuideStatusIcon } from '../guides/GuideStatusIcon';
import { Delete, Settings } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { OrganizationSelect } from '../organization/OrganizationSelect';
import { Changes } from '../components/Changes';
import { Unpacked } from '../graphQLTypes/types';
import { GetRevisionsBySubjectResourceQuery, GetSubjectResourceQuery, GuideStatus, RevisionType } from '../__generated__/graphql';
import { StyledPaper as ThemedPaper } from '../theme';

type SubjectResource = Unpacked<GetSubjectResourceQuery['resource']>;

const PREFIX = 'Details';

const classes = {
  extraDense: `${PREFIX}-extraDense`,
  detailBox: `${PREFIX}-detailBox`,
  completeRevision: `${PREFIX}-completeRevision`,
  smallButton: `${PREFIX}-smallButton`,
};

const StyledPaper = styled(ThemedPaper)({
  [`& .${classes.extraDense}`]: {
    paddingTop: 1,
    paddingBottom: 1,
  },
  [`&.${classes.detailBox}`]: {
    minHeight: 195,
  },
  [`& .${classes.completeRevision}`]: {
    opacity: 0.6,
  },
  [`& .${classes.smallButton}`]: {
    padding: 6,
    float: 'right',
  },
});

export function Details() {
  const { id } = useParams();
  const subjectResourceId = id as string;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h5" component="h3">
          Fagressurs
        </Typography>
      </Grid>
      <Grid container item xs={8}>
        <Grid item xs={6}>
          <DetailsBox subjectResourceId={subjectResourceId} />
        </Grid>
        <Grid item xs={6}>
          <Organization subjectResourceId={subjectResourceId} />
        </Grid>
        <Grid item xs={12}>
          <Revisions subjectResourceId={subjectResourceId} />
        </Grid>
        <Grid item xs={12}>
          <Guides subjectResourceId={subjectResourceId} />
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <ChangeLog subjectResourceId={subjectResourceId} />
      </Grid>
    </Grid>
  );
}

function DetailsBox({ subjectResourceId }: { subjectResourceId: string }) {
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(SUBJECT_RESOURCE, { variables: { id: subjectResourceId } });
  const [subjectResourceDelete, { called }] = useMutation(DELETE, { variables: { input: { id: subjectResourceId } } });

  const doDelete = async () => {
    const result = await subjectResourceDelete();
    navigate('/subjectResource');
  };

  if (error) return <Typography>{error.message}</Typography>;
  if (loading || !data) return <LoadingSpinner />;

  const resource = data.resource;
  if (!resource) return null;
  return (
    <StyledPaper className={classes.detailBox}>
      <Typography variant="h5">{resource.name + (resource.isDeleted ? ' - Slettet' : '')}</Typography>
      <List dense>
        <ListItem classes={{ dense: classes.extraDense }}>
          <ListLabel>Navn</ListLabel>
          <ListItemText>
            <Name resource={resource} />
          </ListItemText>
        </ListItem>
        <ListItem classes={{ dense: classes.extraDense }}>
          <ListLabel>SINTEF</ListLabel>
          <ListItemText>
            <Employee resource={resource} />
          </ListItemText>
        </ListItem>
        <ListItem classes={{ dense: classes.extraDense }}>
          <ListLabel>Tidligere SINTEF</ListLabel>
          <ListItemText>
            <FormerEmployee resource={resource} />
          </ListItemText>
        </ListItem>
        <ListItem classes={{ dense: classes.extraDense }}>
          <ListLabel>Notat</ListLabel>
          <ListItemText>
            <Notes resource={resource} />
          </ListItemText>
        </ListItem>
      </List>
      {resource.isDeletable && (
        <Button disabled={called} onClick={doDelete} startIcon={<Delete />} variant="contained" color="primary">
          Slett fagressurs
        </Button>
      )}
    </StyledPaper>
  );
}

function Name({ resource }: { resource: SubjectResource }) {
  const [subjectResourceUpdateName] = useMutation(UPDATE_NAME);
  if (!resource) return null;

  if (resource.isDeleted) return <span>{resource.name}</span>;

  const value = resource.name ? resource.name : '';
  const setValue = async (input: string) => {
    await subjectResourceUpdateName({ variables: { input: { id: resource.id, name: input } } });
  };
  return <TextEditor placeholder="Navn" value={value} setValue={setValue} />;
}

function Employee({ resource }: { resource: SubjectResource }) {
  const [updateInternal] = useMutation(UPDATE_INTERNAL, { variables: { input: { id: resource?.id, isInternal: !resource?.isInternal } } });
  if (!resource) return null;

  return <Checkbox disabled={resource.isDeleted} style={{ padding: 0 }} checked={resource.isInternal} onChange={() => updateInternal()} />;
}

function FormerEmployee({ resource }: { resource: SubjectResource }) {
  const [updateFormerInternal] = useMutation(UPDATE_HAS_BEEN_INTERNAL, {
    variables: { input: { id: resource?.id, hasBeenInternal: !resource?.hasBeenInternal } },
  });
  if (!resource) return null;

  return <Checkbox disabled={resource.isDeleted} style={{ padding: 0 }} checked={resource.hasBeenInternal} onChange={() => updateFormerInternal()} />;
}

function Notes({ resource }: { resource: SubjectResource }) {
  const [subjectResourceUpdateNotes] = useMutation(UPDATE_NOTES);
  if (!resource) return null;
  if (resource.isDeleted) return <span>{resource.notes}</span>;

  const value = resource.notes ? resource.notes : '';
  const setValue = async (input: string) => {
    await subjectResourceUpdateNotes({ variables: { input: { id: resource.id, notes: input } } });
  };
  return <TextEditor placeholder="" value={value} setValue={setValue} multiline />;
}

export function TextEditor({
  placeholder,
  value,
  setValue,
  multiline,
}: {
  placeholder: string;
  multiline?: boolean;
  value: string;
  setValue: (v: string) => Promise<void>;
}) {
  return (
    <EditableTextField
      value={value}
      TextFieldProps={{
        multiline,
        placeholder,
      }}
      onUpdate={setValue}
    />
  );
}

function ChangeLog({ subjectResourceId }: { subjectResourceId: string }) {
  return <Changes id={subjectResourceId} gqlQuery={GET_CHANGES} />;
}

type Revision = Unpacked<GetRevisionsBySubjectResourceQuery['revisionModels']>;
function Revisions({ subjectResourceId }: { subjectResourceId: string }) {
  const [showComplete, setShowComplete] = useState(false);
  const { loading, error, data } = useQuery(GET_REVISIONS, {
    variables: { id: subjectResourceId },
    fetchPolicy: 'network-only',
  });
  const shouldShow = (rev: Revision): boolean => showComplete || !rev?.completedAt;

  const Counter = () => {
    return data ? <span>({data?.revisionModels?.filter((x) => shouldShow(x)).length})</span> : null;
  };
  return (
    <StyledPaper>
      <Typography variant="h5">
        Revisjoner <Counter />
      </Typography>
      <FormControlLabel control={<Switch onChange={() => setShowComplete(!showComplete)} checked={showComplete} />} label="Vis ferdige revisjoner" />
      {error && <Typography>{error.message}</Typography>}
      {loading && <LoadingSpinner />}
      {data && data.revisionModels && (
        <Table size="small">
          <ColWidths widths={[40, null, 40, 200, 40, 150]} />
          <TableHead>
            <TableRow>
              <TableCellHead>Nummer</TableCellHead>
              <TableCellHead>Tittel</TableCellHead>
              <TableCellHead>Opprettet</TableCellHead>
              <TableCellHead>Rolle</TableCellHead>
              <TableCellHead>Framdrift</TableCellHead>
              <TableCellHead>Revisjonstype</TableCellHead>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.revisionModels
              .filter((x) => shouldShow(x))
              .map((x) => {
                if (!x) return null;
                return (
                  <TableRow key={x.id} className={x.completedAt ? classes.completeRevision : undefined}>
                    <TableCell>
                      <StyledLink to={`/revision/${x.id}`}>{x.guide?.docName}</StyledLink>
                    </TableCell>
                    <TableCellEllipsis>{x.guide?.docTitle}</TableCellEllipsis>
                    <TableCell>{formatDate(x.createdAt, 'DD.MM.YYYY')}</TableCell>
                    <TableCell>
                      <RoleInRevision revision={x} subjectResourceId={subjectResourceId} />
                    </TableCell>
                    <TableCell>{`${x.progress}%`}</TableCell>
                    <TableCellEllipsis>
                      <RevisionTypeLabel type={x.type} />
                    </TableCellEllipsis>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      )}
    </StyledPaper>
  );
}

function Guides({ subjectResourceId }: { subjectResourceId: string }) {
  const { loading, error, data } = useQuery(GET_GUIDES, {
    variables: { id: subjectResourceId },
    fetchPolicy: 'network-only',
  });
  const Counter = () => {
    return data ? <span>({data?.guideModels?.length})</span> : null;
  };

  return (
    <StyledPaper>
      <Typography variant="h5">
        Anvisninger <Counter />
      </Typography>
      {error && <Typography>{error.message}</Typography>}
      {loading && <LoadingSpinner />}
      {data && data.guideModels && (
        <Table size="small">
          <ColWidths widths={[40, 40, null, 150, 40]} />
          <TableHead>
            <TableRow>
              <TableCellHead>Status</TableCellHead>
              <TableCellHead>Nummer</TableCellHead>
              <TableCellHead>Tittel</TableCellHead>
              <TableCellHead>Fagområde</TableCellHead>
              <TableCellHead>Sist publisert</TableCellHead>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.guideModels.map((x) => {
              if (!x) return null;
              const categoryTitle = x.mainCategory ? x.mainCategory.title : null;
              return (
                <TableRow key={x.id}>
                  <TableCellIcon>
                    <GuideStatusIcon
                      status={x.status ?? GuideStatus.Planned}
                      hasRevision={x.ongoingRevision !== null}
                      hasChangesSinceLastPublish={x.hasChangesSinceLastPublish}
                    />
                  </TableCellIcon>
                  <TableCell>
                    <StyledLink to={`/guide/${x.id}`}>{x.docName}</StyledLink>
                  </TableCell>
                  <TableCellEllipsis title={x.docTitle}>{x.docTitle}</TableCellEllipsis>
                  <TableCellEllipsis title={categoryTitle}>{categoryTitle}</TableCellEllipsis>
                  <TableCell>{formatDateOnly(x.lastPublishedAt)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
    </StyledPaper>
  );
}

function Organization({ subjectResourceId }: { subjectResourceId: string }) {
  const { loading, error, data } = useQuery(SUBJECT_RESOURCE, { variables: { id: subjectResourceId } });
  const [subjectResourceUpdateUnit, { called }] = useMutation(UPDATE_UNIT);
  const navigate = useNavigate();
  if (error) return <Typography>{error.message}</Typography>;
  if (loading || !data) return <LoadingSpinner />;

  const updateUnit = (unitId: string, departmentId: string) => {
    subjectResourceUpdateUnit({ variables: { input: { id: subjectResourceId, unitId, departmentId } } });
  };

  if (!data.resource) return null;
  return (
    <StyledPaper className={classes.detailBox}>
      <Typography variant="h5" gutterBottom>
        Organisasjon
        <IconButton title="Endre organisasjon" className={classes.smallButton} onClick={() => navigate('/organization')} size="large">
          <Settings fontSize="small" />
        </IconButton>
      </Typography>
      {data.resource.isInternal && <OrganizationSelect unit={data.resource.unitId} onChanged={updateUnit} changing={called} />}
      {!data.resource.isInternal && <Typography variant="subtitle1">Ikke tilgjengelig for eksterne</Typography>}
    </StyledPaper>
  );
}

function RevisionTypeLabel({ type }: { type: RevisionType }) {
  switch (type) {
    case RevisionType.Revision:
      return <span>Fullstendig revisjon</span>;
    case RevisionType.Simple:
      return <span>Begrenset revisjon</span>;
    case RevisionType.Correction:
      return <span>Feilretting (utgår) </span>;
    case RevisionType.Withdrawal:
      return <span>Tilbaketrekke</span>;
    default:
      return <span>{type}</span>;
  }
}

function RoleInRevision({ revision, subjectResourceId }: { revision: Revision; subjectResourceId: string }) {
  const isAuthor = revision?.authors?.some((x) => x?.id === subjectResourceId);
  const isQa = revision?.professionQualityAssurers?.some((x) => x?.id === subjectResourceId);
  const qaLabel = 'Faglig kvalitetssikrer';
  const authorLabel = 'Forfatter';
  if (isAuthor && isQa) return <span>{`${qaLabel} og ${authorLabel}`}</span>;
  if (isAuthor) return <span>{authorLabel}</span>;
  if (isQa) return <span>{qaLabel}</span>;
  return null;
}
