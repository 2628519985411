import React from 'react';
import { ListLabel, PreWrap } from '../Components';

import { List, ListItem, ListItemText, Typography, IconButton, Button, Stack } from '@mui/material';
import { NeedForRevisionDialog, NeedForRevisionDialogInput, NeedForRevisionLabel } from './NeedForRevisionDialog';
import { Edit, Delete, Info } from '@mui/icons-material';
import { useMutation } from '@apollo/client';
import { SET_NEED_FOR_REVISION } from './backlog.graphql';
import { GetGuideQuery, NeedForRevision } from '../__generated__/graphql';
import { StyledPaper } from '../theme';
import { useModal } from '../dialogs/useModal';
import { RankDetailsDialog, RankDetailsDialogProps } from './RankDetailsDialog';
import { LicensePageViewStatForGuideDialog, LicensePageViewStatForGuideDialogProps } from '../guides/LicensePageViewStatDialog';

export type Guide = NonNullable<GetGuideQuery['guide']>;

export function Backlog({ guide }: { guide: Guide }) {
  const modalDetails = useModal<RankDetailsDialogProps, unknown>({ data: { guideId: guide.id, docName: guide.docName, docTitle: guide.docTitle } });
  const modalStats = useModal<LicensePageViewStatForGuideDialogProps, undefined>({ data: guide });

  const showPageViewsPerLicense = () => {
    modalStats.open();
  };

  const showRankDetails = () => {
    modalDetails.open();
  };

  return (
    <StyledPaper sx={{ minHeight: 260 }}>
      <Typography variant="h5" component="h3">
        Planleggingsdetaljer
      </Typography>
      <Details guide={guide} showPageViewsPerLicense={showPageViewsPerLicense} showRankDetails={showRankDetails} />
      {modalDetails.isOpen && <RankDetailsDialog modal={modalDetails} />}
      {modalStats.isOpen && <LicensePageViewStatForGuideDialog modal={modalStats} />}
    </StyledPaper>
  );
}

function Details({
  guide,
  showPageViewsPerLicense,
  showRankDetails,
}: {
  guide: NonNullable<Guide>;
  showPageViewsPerLicense(): void;
  showRankDetails(): void;
}) {
  const needForRevisionModal = useModal<NeedForRevisionDialogInput, unknown>({ data: guide });
  const [clearNeedForRevision, { loading }] = useMutation(SET_NEED_FOR_REVISION, {
    variables: { input: { id: guide.id, needForRevision: NeedForRevision.Undefined, comment: null } },
  });

  const openNeedForRevisionDialog = async () => {
    await needForRevisionModal.open(guide);
  };

  const score = Object.keys(guide.planningScores!)
    .filter((x) => x !== '__typename')
    .map((x) => guide.planningScores![x].score)
    .reduce((acc, elem) => acc + elem, 0);
  return (
    <>
      <List>
        <ListItem sx={{ paddingTop: '2px', paddingBottom: '2px', alignItems: 'flex-start' }}>
          <ListLabel>
            Score:
            <IconButton
              sx={{ padding: '2px' }}
              href="https://byggforsk.atlassian.net/wiki/spaces/FOU/pages/263028741"
              target="_blank"
              rel="noopener noreferrer"
              size="large">
              <Info fontSize="small" />
            </IconButton>
          </ListLabel>
          <ListItemText>
            {score}
            <Button onClick={showRankDetails}>
              <span style={{ paddingLeft: 20 }}>Vis kriterier ...</span>
            </Button>
          </ListItemText>
        </ListItem>
        <ListItem sx={{ paddingTop: '2px', paddingBottom: '2px', alignItems: 'flex-start' }}>
          <ListLabel>Visninger:</ListLabel>
          <ListItemText>
            <span
              title={`${guide.docName?.startsWith('BVN') ? 'BVN' : 'BFS'}: nr ${
                (guide.planningScores?.pageViews?.placement?.index ?? 0) + 1
              } av ${guide.planningScores?.pageViews?.placement?.total}`}>
              {Number(guide.planningScores!.pageViews!.value).toLocaleString('no-NO')} visninger de siste 365 dagene.
            </span>
          </ListItemText>
        </ListItem>

        <ListItem>
          <ListLabel></ListLabel>
          <ListItemText>
            <Button onClick={showPageViewsPerLicense}>Visninger per kunde ...</Button>
          </ListItemText>
        </ListItem>

        <ListItem sx={{ paddingTop: '2px', paddingBottom: '2px', alignItems: 'flex-start' }}>
          <ListLabel>Behov for revisjon:</ListLabel>
          <ListItemText
            sx={{ marginTop: 0.25 }}
            disableTypography
            primary={
              <Stack direction="row" spacing={1}>
                <Typography variant="subtitle1" color={guide.needForRevision ? 'textPrimary' : 'textSecondary'}>
                  {guide.needForRevision && (
                    <span>
                      <NeedForRevisionNumber needForRevision={guide.needForRevision} /> -{' '}
                    </span>
                  )}
                  <NeedForRevisionLabel needForRevision={guide.needForRevision!} />
                </Typography>
                <IconButton onClick={openNeedForRevisionDialog} aria-label="Angi behov for revisjon" size="small">
                  <Edit fontSize="small" />
                </IconButton>
                <IconButton
                  onClick={() => clearNeedForRevision()}
                  aria-label="Nullstill behov for revisjon"
                  disabled={!guide.needForRevision || loading}
                  size="small">
                  <Delete fontSize="small" />
                </IconButton>
              </Stack>
            }
            secondary={<PreWrap>{guide.needForRevisionComment}</PreWrap>}
          />
        </ListItem>
      </List>
      {needForRevisionModal.isOpen && <NeedForRevisionDialog modal={needForRevisionModal} />}
    </>
  );
}

function NeedForRevisionNumber({ needForRevision }: { needForRevision: NeedForRevision }) {
  switch (needForRevision) {
    case NeedForRevision.Critical:
      return <span>1</span>;
    case NeedForRevision.High:
      return <span>2</span>;
    case NeedForRevision.Medium:
      return <span>3</span>;
    case NeedForRevision.Low:
      return <span>4</span>;
    case NeedForRevision.NextTime:
      return <span>5</span>;
    default:
      return null;
  }
}
