import React, { useState } from 'react';
import {
  SvgIcon,
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  Snackbar,
  Alert as MuiAlert,
  CircularProgress,
} from '@mui/material';
import { CloudUpload, Delete, Description, Image } from '@mui/icons-material';
import Dropzone, { Accept } from 'react-dropzone';
import { useConfig } from '../env';
import { useMutation } from '@apollo/client';
import { formatDateOnly } from '../Formatters';
import { CLEAR_CONTENT, REMOVE_ATTACHMENT, UPLOAD_ATTACHMENT, REMOVE_IMAGE_FILE, UPLOAD_CONTENT, UPLOAD_IMAGE_FILE } from './guide.graphql';
import { ColWidths, compareFn, TableCellIcon } from '../components/TableComponents';
import { Guide } from './Details';
import { Unpacked } from '../graphQLTypes/types';
import { GuideStatus } from '../__generated__/graphql';
import { StyledPaper } from '../theme';

export function Uploader({ guide, elevation }: { guide: Guide; elevation?: number }) {
  return (
    <StyledPaper elevation={elevation}>
      <Typography variant="h6" component="h3" gutterBottom>
        Tekst
      </Typography>
      <Document guide={guide} />
      <Images guide={guide} />
      <Attachments guide={guide} />
    </StyledPaper>
  );
}

function Document({ guide }: { guide: Guide }) {
  const config = useConfig();
  const [showDialog, setShowDialog] = useState(false);
  const [showFileUploaded, setShowFileUploaded] = useState(false);
  const [clearContent, { loading: clearing }] = useMutation(CLEAR_CONTENT, { variables: { input: { id: guide!.id } } });
  const [uploadContent, { loading: uploading }] = useMutation(UPLOAD_CONTENT);

  if (!guide) return null;
  const documentUrl = config ? `${config.apiUrl}/document/${guide!.id}` : null;
  const handleClose = () => {
    if (!uploading) setShowDialog(false);
  };
  const onDrop = async (files: File[]) => {
    if (files.length > 0) {
      await uploadContent({ variables: { input: { id: guide.id, file: files[0] } } });
      setShowDialog(false);
      setShowFileUploaded(true);
    }
  };

  const hasFile = guide.docVersion !== 0;
  const timestamp = formatDateOnly(guide.docUpdated);
  const hasTimestamp = timestamp !== '01.01.0001';
  const myRef = React.useRef(null);
  const isNew = guide.status === GuideStatus.Planned;

  return (
    <>
      <Button
        variant={hasFile ? 'outlined' : 'contained'}
        color={hasFile ? 'inherit' : 'primary'}
        style={{ marginLeft: 16 }}
        onClick={() => setShowDialog(true)}
        startIcon={<CloudUpload />}>
        Last opp ny Word-fil
      </Button>
      {!hasFile && (
        <Typography color="textSecondary" style={{ marginLeft: 16 }}>
          Ingen Word-fil er lastet opp
        </Typography>
      )}
      {hasFile && (
        <Table ref={myRef} size="small">
          <ColWidths widths={[60, null, 150, 76]} />

          <TableHead>
            <TableRow>
              <TableCell>Fil</TableCell>
              <TableCell>Navn</TableCell>
              <TableCell>Lastet opp</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCellIcon>
                <WordIcon />
              </TableCellIcon>
              <TableCell>{documentUrl && <a href={documentUrl}>{guide.docFileName ? guide.docFileName : '<Mangler navn>'}</a>}</TableCell>
              <TableCell>{hasTimestamp ? timestamp : null}</TableCell>
              <TableCellIcon>
                {isNew && (
                  <IconButton disabled={clearing || uploading} onClick={() => clearContent()} size="large">
                    <Delete fontSize="small" />
                  </IconButton>
                )}
              </TableCellIcon>
            </TableRow>
          </TableBody>
        </Table>
      )}
      <Dialog open={showDialog} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Last opp Word-fil til {guide.docName}</DialogTitle>
        <DialogContent>
          <FileChooser accept={{ 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'] }} onDrop={onDrop} />
        </DialogContent>
        <DialogActions>
          {uploading && <CircularProgress title="Laster opp fil" size={25} />}
          <Button disabled={uploading} onClick={handleClose}>
            Avbryt
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={showFileUploaded} autoHideDuration={6000} TransitionComponent={undefined} onClose={() => setShowFileUploaded(false)}>
        <MuiAlert onClose={handleClose} severity="success">
          Ny fil lastet opp!
        </MuiAlert>
      </Snackbar>
    </>
  );
}

function FileChooser({ disabled, onDrop, accept }: { disabled?: boolean; accept?: Accept; onDrop: (newFiles: File[]) => void }) {
  return (
    <div style={{ width: 330, height: 150, marginLeft: 'auto', marginRight: 'auto' }}>
      <div style={{ position: 'absolute' }}>
        <Dropzone disabled={disabled} onDrop={onDrop} accept={accept}>
          {({ getRootProps, getInputProps }) => (
            <section>
              <div
                style={{
                  alignItems: 'center',
                  padding: 20,
                  borderWidth: 2,
                  borderRadius: 2,
                  borderColor: '#eeeeee',
                  borderStyle: 'dashed',
                  backgroundColor: '#fafafa',
                  color: '#bdbdbd',
                  outline: 'none',
                  marginTop: 30,
                }}
                {...getRootProps()}>
                <input {...getInputProps()} />
                <p>Dra og slipp fil her, eller klikk for å velge</p>
              </div>
            </section>
          )}
        </Dropzone>
      </div>
    </div>
  );
}

function Images({ guide }: { guide: Guide }) {
  const config = useConfig();
  const [selectedImageId, setSelectedImageId] = useState<number | undefined>(undefined);
  const imageUrl = config ? `${config.apiUrl}/image/${guide!.id}` : null;
  const [deleteImage, { loading: deleting }] = useMutation(REMOVE_IMAGE_FILE);
  const [uploadImage, { loading: uploading }] = useMutation(UPLOAD_IMAGE_FILE);
  if (!guide) return null;
  const doDeleteImage = (imageId: number, imageFileId: number, fileName: string) =>
    deleteImage({ variables: { input: { id: guide.id, imageId: imageId, imageFileId, fileName } } });

  const [showDialog, setShowDialog] = useState(false);
  const handleClose = () => setShowDialog(false);
  const onDrop = async (files: File[]) => {
    if (files.length === 0) return;
    await uploadImage({ variables: { input: { id: guide.id, imageId: selectedImageId!, file: files[0] } } });
    setShowDialog(false);
  };

  const hasImages = guide.images && guide.images.length > 0;

  const files = getAllImageFiles(guide).sort(compareFn<ImageDisplayFile>((x) => x.fileName));
  return (
    <>
      <Button
        variant="outlined"
        disabled={!hasImages || !guide.hasNewContent}
        style={{ marginLeft: 16, marginTop: 30 }}
        onClick={() => setShowDialog(true)}
        title="Last opp nytt bilde"
        startIcon={<CloudUpload />}>
        Last opp vedlegg til figurer
      </Button>
      {!hasImages && (
        <Typography color="textSecondary" style={{ marginLeft: 16 }}>
          Ingen figurer funnet i Word-filen
        </Typography>
      )}
      {hasImages && (
        <>
          <Table size="small">
            <ColWidths widths={[60, null, null, 150, 76]} />
            <TableHead>
              <TableRow>
                <TableCell>Fil</TableCell>
                <TableCell>Navn</TableCell>
                <TableCell>Figur</TableCell>
                <TableCell>Lastet opp</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {files.map((x) => (
                <TableRow key={`${x.imageId}${x.imageFileId}`}>
                  <TableCellIcon>
                    <Image fontSize="small" />
                  </TableCellIcon>
                  <TableCell>
                    <a href={`${imageUrl}?imageId=${x.imageId}&imageFileId=${x.imageFileId}`}>{x.fileName}</a>
                  </TableCell>
                  <TableCell>{x.imageName ? x.imageName : x.imageNumber}</TableCell>
                  <TableCell>{formatDateOnly(x.uploadedAt)}</TableCell>
                  <TableCellIcon>
                    <IconButton disabled={!guide.hasNewContent} onClick={() => doDeleteImage(x.imageId, x.imageFileId, x.fileName!)} size="large">
                      <Delete fontSize="small" />
                    </IconButton>
                  </TableCellIcon>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Dialog open={showDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Last opp nye bilder</DialogTitle>
            <DialogContent>
              <Grid container>
                <Grid item xs={6}>
                  <FormControl sx={{ marginTop: 1 }}>
                    <InputLabel id="figur-select-label">Figur</InputLabel>
                    <Select
                      labelId="figur-select-label"
                      id="figur-select"
                      variant="standard"
                      value={selectedImageId ? selectedImageId : 0}
                      onChange={(ev) => setSelectedImageId(ev.target.value !== 0 ? Number(ev.target.value) : undefined)}>
                      <MenuItem value={0}>
                        <em>Ingen valgt</em>
                      </MenuItem>
                      {guide.images?.map((img) => {
                        if (!img) return null;
                        return (
                          <MenuItem key={img.id} value={img.id}>
                            {img.name ? img.name : img.number}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText>Velg hvilken figur bildet skal ligge under.</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  {selectedImageId && (
                    <img
                      alt={'Bilde av valgt figur'}
                      style={{ marginLeft: 10, maxHeight: 100, maxWidth: 240 }}
                      src={`${imageUrl}?imageId=${selectedImageId}`}
                    />
                  )}
                </Grid>
              </Grid>
              <FileChooser disabled={!selectedImageId || uploading} onDrop={onDrop} accept={{ 'application/acad': ['.dwg'] }} />
            </DialogContent>
            <DialogActions>
              {uploading && <CircularProgress title="Laster opp fil" size={25} />}
              <Button disabled={uploading} onClick={handleClose}>
                Avbryt
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
}

type ImageFile = Unpacked<NonNullable<Unpacked<NonNullable<Guide>['images']>>['files']>;

type ImageDisplayFile = ImageFile & {
  imageId: number;
  imageNumber: string;
  imageName: string;
};

function getAllImageFiles(guide: Guide): ImageDisplayFile[] {
  const flat: ImageDisplayFile[] = [];
  if (!guide?.images) return flat;
  guide.images?.forEach((image) => {
    image?.files?.forEach((file) => {
      if (file) flat.push({ imageId: image.id, imageNumber: image.number!, imageName: image.name!, ...file });
    });
  });
  return flat;
}

type GuideAttachment = Unpacked<NonNullable<Guide>['attachments']>;

function Attachments({ guide }: { guide: Guide }) {
  const config = useConfig();
  const [currentAttachment, setCurrentAttachement] = useState<GuideAttachment | undefined>(undefined);
  const attachmentUrl = config ? `${config.apiUrl}/attachment/${guide!.id}` : null;
  const [uploadAttachment, { loading: uploading }] = useMutation(UPLOAD_ATTACHMENT);
  const [deleteAttachment, { loading: deleting }] = useMutation(REMOVE_ATTACHMENT);
  const handleClose = () => setCurrentAttachement(undefined);
  const onDrop = async (files: File[]) => {
    await uploadAttachment({ variables: { input: { id: guide!.id, attachmentId: currentAttachment!.id, file: files[0] } } });
    setCurrentAttachement(undefined);
  };
  const doDeleteAttachment = (attachmentId: number, name: string) =>
    deleteAttachment({ variables: { input: { id: guide!.id, attachmentId, name } } });
  const hasAttachments = guide?.attachments && guide.attachments.length > 0;

  return (
    <>
      <Typography variant="body1" component="h3" style={{ marginLeft: 16, marginTop: 30 }}>
        Vedlegg til anvisning
      </Typography>
      {!hasAttachments && (
        <Typography color="textSecondary" style={{ marginLeft: 16 }}>
          Ingen filreferanser funnet i Word-filen
        </Typography>
      )}
      {hasAttachments && (
        <Table size="small">
          <ColWidths widths={[60, null, 150, 76]} />
          <TableHead>
            <TableRow>
              <TableCell>Fil</TableCell>
              <TableCell>Navn</TableCell>
              <TableCell>Lastet opp</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {[...guide.attachments!].sort(compareFn<GuideAttachment>((x) => x!.name)).map((x) => {
              if (!x) return null;
              return (
                <TableRow key={x.id}>
                  <TableCellIcon>
                    <FileIcon filename={x.name!} />
                  </TableCellIcon>
                  {x.uploaded && (
                    <TableCell>
                      <a href={`${attachmentUrl}?attachmentId=${x.id}`}>{x.name}</a>
                    </TableCell>
                  )}
                  {!x.uploaded && (
                    <TableCell title="Må laste opp filreferanser som vedlegg" style={{ color: '#CF453A' }}>
                      {x.name}*
                    </TableCell>
                  )}
                  <TableCell>{x.createdAt ? formatDateOnly(x.createdAt) : ''}</TableCell>
                  {x.uploaded && (
                    <TableCellIcon>
                      <IconButton
                        disabled={deleting || !guide.hasNewContent}
                        title="Fjern opplastet fil"
                        onClick={() => doDeleteAttachment(x.id, x.name!)}
                        size="large">
                        <Delete fontSize="small" />
                      </IconButton>
                    </TableCellIcon>
                  )}
                  {!x.uploaded && (
                    <TableCellIcon>
                      <IconButton title="Last opp fil" disabled={!guide.hasNewContent} onClick={() => setCurrentAttachement(x)} size="large">
                        <CloudUpload color="primary" fontSize="small" />
                      </IconButton>
                    </TableCellIcon>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
      <Dialog open={currentAttachment !== undefined} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Last opp nytt vedlegg</DialogTitle>
        <DialogContent>
          <FileChooser disabled={uploading} onDrop={onDrop} />
        </DialogContent>
        <DialogActions>
          {uploading && <CircularProgress title="Laster opp fil" size={25} />}
          <Button disabled={uploading} onClick={handleClose}>
            Avbryt
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function FileIcon({ filename }: { filename: string }) {
  if (filename.endsWith('.docx') || filename.endsWith('doc')) return <WordIcon />;
  if (filename.endsWith('.pdf')) return <PdfIcon />;

  return <Description />;
}

function WordIcon() {
  return (
    <SvgIcon viewBox="0 0 32 32">
      <path
        fill="#FFF"
        stroke="#979593"
        strokeMiterlimit="10"
        d="M23.086 2.5H9.5a1 1 0 00-1 1v25a1 1 0 001 1h19a1 1 0 001-1V8.914a1 1 0 00-.293-.707l-5.414-5.414a1 1 0 00-.707-.293z"
      />
      <path fill="none" stroke="#979593" strokeMiterlimit="10" d="M23.5 2.5v5a1 1 0 001 1h5" />
      <g fill="#C8C6C4">
        <path d="M26.5 15h-11a.5.5 0 010-1h11a.5.5 0 010 1zM26.5 17h-11a.5.5 0 010-1h11a.5.5 0 010 1zM26.5 19h-11a.5.5 0 010-1h11a.5.5 0 010 1zM26.5 21h-11a.5.5 0 010-1h11a.5.5 0 010 1zM26.5 23h-11a.5.5 0 010-1h11a.5.5 0 010 1z" />
      </g>
      <path fill="#185ABD" d="M3.5 25h11a1.5 1.5 0 001.5-1.5v-11a1.5 1.5 0 00-1.5-1.5h-11A1.5 1.5 0 002 12.5v11A1.5 1.5 0 003.5 25z" />
      <path
        fill="#F9F7F7"
        d="M7.215 20.73h.022C7.246 20.615 8.37 15 8.37 15h1.306s1.14 5.353 1.175 5.72h.017c.015-.252.944-5.72.944-5.72H13l-1.462 7h-1.389s-1.153-5.53-1.162-5.628h-.018C8.959 16.485 7.886 22 7.886 22h-1.41L5 15h1.21s1 5.607 1.005 5.73z"
      />
    </SvgIcon>
  );
}

function PdfIcon() {
  return (
    <SvgIcon viewBox="-0.4 0 75.320129 92.604164">
      <g transform="translate(36.076172,-93.731774)">
        <g transform="matrix(1.4843054,0,0,1.4843054,17.471885,-90.243502)">
          <g>
            <path
              fill="#ff2116"
              d="m -29.632812,123.94727 c -3.551967,0 -6.44336,2.89347 -6.44336,6.44531 v 49.49804 c 0,3.55185 2.891393,6.44532 6.44336,6.44532 H 8.2167969 c 3.5519661,0 6.4433591,-2.89335 6.4433591,-6.44532 v -40.70117 c 0,0 0.101353,-1.19181 -0.416015,-2.35156 -0.484969,-1.08711 -1.275391,-1.84375 -1.275391,-1.84375 a 1.0584391,1.0584391 0 0 0 -0.0059,-0.008 L 3.5722246,125.7752 a 1.0584391,1.0584391 0 0 0 -0.015625,-0.0156 c 0,0 -0.8017392,-0.76344 -1.9902344,-1.27344 -1.39939552,-0.6005 -2.8417968,-0.53711 -2.8417968,-0.53711 l 0.021484,-0.002 z"
            />
            <path
              fill="#f5f5f5"
              d="m -29.632812,126.06445 h 28.3789058 a 1.0584391,1.0584391 0 0 0 0.021484,0 c 0,0 1.13480448,0.011 1.96484378,0.36719 0.79889772,0.34282 1.36536982,0.86176 1.36914062,0.86524 1.25e-5,1e-5 0.00391,0.004 0.00391,0.004 l 9.3671868,9.18945 c 0,0 0.564354,0.59582 0.837891,1.20899 0.220779,0.49491 0.234375,1.40039 0.234375,1.40039 a 1.0584391,1.0584391 0 0 0 -0.002,0.0449 v 40.74609 c 0,2.41592 -1.910258,4.32813 -4.3261717,4.32813 H -29.632812 c -2.415914,0 -4.326172,-1.91209 -4.326172,-4.32813 v -49.49804 c 0,-2.41603 1.910258,-4.32813 4.326172,-4.32813 z"
            />
            <path
              fill="#ff2116"
              d="m -23.40766,161.09299 c -1.45669,-1.45669 0.11934,-3.45839 4.39648,-5.58397 l 2.69124,-1.33743 1.04845,-2.29399 c 0.57665,-1.26169 1.43729,-3.32036 1.91254,-4.5748 l 0.8641,-2.28082 -0.59546,-1.68793 c -0.73217,-2.07547 -0.99326,-5.19438 -0.52872,-6.31588 0.62923,-1.51909 2.69029,-1.36323 3.50626,0.26515 0.63727,1.27176 0.57212,3.57488 -0.18329,6.47946 l -0.6193,2.38125 0.5455,0.92604 c 0.30003,0.50932 1.1764,1.71867 1.9475,2.68743 l 1.44924,1.80272 1.8033728,-0.23533 c 5.72900399,-0.74758 7.6912472,0.523 7.6912472,2.34476 0,2.29921 -4.4984914,2.48899 -8.2760865,-0.16423 -0.8499666,-0.59698 -1.4336605,-1.19001 -1.4336605,-1.19001 0,0 -2.3665326,0.48178 -3.531704,0.79583 -1.202707,0.32417 -1.80274,0.52719 -3.564509,1.12186 0,0 -0.61814,0.89767 -1.02094,1.55026 -1.49858,2.4279 -3.24833,4.43998 -4.49793,5.1723 -1.3991,0.81993 -2.86584,0.87582 -3.60433,0.13733 z m 2.28605,-0.81668 c 0.81883,-0.50607 2.47616,-2.46625 3.62341,-4.28553 l 0.46449,-0.73658 -2.11497,1.06339 c -3.26655,1.64239 -4.76093,3.19033 -3.98386,4.12664 0.43653,0.52598 0.95874,0.48237 2.01093,-0.16792 z m 21.21809,-5.95578 c 0.80089,-0.56097 0.68463,-1.69142 -0.22082,-2.1472 -0.70466,-0.35471 -1.2726074,-0.42759 -3.1031574,-0.40057 -1.1249,0.0767 -2.9337647,0.3034 -3.2403347,0.37237 0,0 0.993716,0.68678 1.434896,0.93922 0.58731,0.33544 2.0145161,0.95811 3.0565161,1.27706 1.02785,0.31461 1.6224,0.28144 2.0729,-0.0409 z m -8.53152,-3.54594 c -0.4847,-0.50952 -1.30889,-1.57296 -1.83152,-2.3632 -0.68353,-0.89643 -1.02629,-1.52887 -1.02629,-1.52887 0,0 -0.4996,1.60694 -0.90948,2.57394 l -1.27876,3.16076 -0.37075,0.71695 c 0,0 1.971043,-0.64627 2.97389,-0.90822 1.0621668,-0.27744 3.21787,-0.70134 3.21787,-0.70134 z m -2.74938,-11.02573 c 0.12363,-1.0375 0.1761,-2.07346 -0.15724,-2.59587 -0.9246,-1.01077 -2.04057,-0.16787 -1.85154,2.23517 0.0636,0.8084 0.26443,2.19033 0.53292,3.04209 l 0.48817,1.54863 0.34358,-1.16638 c 0.18897,-0.64151 0.47882,-2.02015 0.64411,-3.06364 z"
            />
            <g>
              <path d="m -20.930423,167.83862 h 2.364986 q 1.133514,0 1.840213,0.2169 0.706698,0.20991 1.189489,0.9446 0.482795,0.72769 0.482795,1.75625 0,0.94459 -0.391832,1.6233 -0.391833,0.67871 -1.056548,0.97958 -0.65772,0.30087 -2.02913,0.30087 h -0.818651 v 3.72941 h -1.581322 z m 1.581322,1.22447 v 3.33058 h 0.783664 q 1.049552,0 1.44838,-0.39184 0.405826,-0.39183 0.405826,-1.27345 0,-0.65772 -0.265887,-1.06355 -0.265884,-0.41282 -0.587747,-0.50378 -0.314866,-0.098 -1.000572,-0.098 z" />
              <path d="m -13.842461,167.83862 h 2.148082 q 1.560333,0 2.4909318,0.55276 0.9375993,0.55276 1.4133973,1.6443 0.482791,1.09153 0.482791,2.42096 0,1.3994 -0.4338151,2.49793 -0.4268149,1.09153 -1.3154348,1.76324 -0.8816233,0.67172 -2.5189212,0.67172 h -2.267031 z m 1.581326,1.26645 v 7.018 h 0.657715 q 1.378411,0 2.001144,-0.9516 0.6227329,-0.95858 0.6227329,-2.5539 0,-3.5125 -2.6238769,-3.5125 z" />
              <path d="m -5.7889096,167.83862 h 5.30372941 v 1.26645 H -4.2075842 v 2.85478 h 2.9807225 v 1.26646 h -2.9807225 v 4.16322 h -1.5813254 z" />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
