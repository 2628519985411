import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { BackButton } from '../Components';
import { Checklist } from './Checklist';
import { RevisionDetails } from './RevisionDetails';
import { RevisionFreeText } from './RevisionFreeText';
import { EditableTextField } from '../components/EditableTextField';
import { REVISION_BY_ID, GET_CHANGES, UPDATE_WORKING_TITLE } from './revisions.graphql';
import { useMutation } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { Typography } from '@mui/material';
import { Changes } from '../components/Changes';
import LoadingSpinner from '../LoadingSpinner';
import { useParams } from 'react-router-dom';
import { Roles } from './Roles';
import { GetRevisionQuery } from '../__generated__/graphql';
import { Unpacked } from '../graphQLTypes/types';

export type Revision = Unpacked<GetRevisionQuery['revision']>;
export type User = Unpacked<GetRevisionQuery['users']>;

export function Details() {
  const { id } = useParams();
  const [validate, setValidate] = useState(false);
  const { data, loading } = useQuery(REVISION_BY_ID, { variables: { revisionId: id } });

  const revision = data?.revision;
  const users = data?.users;

  return (
    <>
      {loading && <LoadingSpinner />}
      {revision && users && (
        <Grid container>
          <Grid item xs={12}>
            <Title revision={revision} />
          </Grid>
          <Grid item xs={11} style={{ paddingLeft: 10 }}>
            <WorkingTitle revision={revision} />
          </Grid>
          <Grid item xs={7}>
            <Checklist revision={revision} history={revision.completedAt ? true : false} />
          </Grid>
          <Grid item xs={5}>
            <RevisionDetails revision={revision} readonly={revision.completedAt ? true : false} validate={validate} setValidate={setValidate} />
            <Roles revision={revision} users={users} history={revision.completedAt ? true : false} validate={validate} />
            <RevisionFreeText revision={revision} readonly={revision.completedAt ? true : false} />
            <Changes id={revision.id} gqlQuery={GET_CHANGES} />
          </Grid>
        </Grid>
      )}
    </>
  );
}

function Title({ revision }: { revision: Revision }) {
  return (
    <Typography variant="h5">
      <BackButton />
      <span>{revision?.title}</span>
    </Typography>
  );
}

function WorkingTitle({ revision }: { revision: Revision }) {
  const [updateWorkingTitle] = useMutation(UPDATE_WORKING_TITLE);
  if (revision?.completedAt) {
    return <Typography component="h3">{revision.workingTitle ?? 'Ingen arbeidstittel'}</Typography>;
  }

  return (
    <Grid container direction="row" alignItems="center">
      <Grid item style={{ paddingRight: 5 }}>
        <span>Arbeidstittel:</span>
      </Grid>
      <Grid item>
        <EditableTextField
          value={revision?.workingTitle ?? ''}
          onUpdate={async (value) => {
            await updateWorkingTitle({ variables: { input: { id: revision?.id, title: value } } });
          }}
        />
      </Grid>
    </Grid>
  );
}
