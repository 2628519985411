import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Add from '@mui/icons-material/Add';
import { formatDate } from '../Formatters';
import { SortBy } from '../Components';
import { Accordion, AccordionDetails, AccordionSummary, Grid, FormControlLabel, Switch, ListItemIcon } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { PreWrap } from '../Components';
import { Notice } from './NoticeIcon';
import { NoticeActions } from './NoticeActions';
import { GetGuideQuery } from '../__generated__/graphql';
import { Unpacked } from '../graphQLTypes/types';
import { StyledPaper } from '../theme';
import { useModal } from '../dialogs/useModal';
import { AddGuideNoticeDialog } from './AddGuideNoticeDialog';

type Notice = Unpacked<NonNullable<GetGuideQuery['guide']>['notices']>;
export function NoticesForGuide({ guideId, notices, elevation }: { guideId: string; notices: Notice[]; elevation?: number }) {
  const modal = useModal<string, unknown>({ data: guideId });

  return (
    <StyledPaper elevation={elevation}>
      <Typography variant="h6" component="h3">
        Merknader (gul lapp)
        <IconButton onClick={() => modal.open()} aria-label="Ny merknad" size="large">
          <Add />
        </IconButton>
        <Notice style={{ float: 'right' }} fontSize="large" color />
      </Typography>
      <Typography variant="caption" color="textSecondary">
        (til kunde)
      </Typography>
      <NoticeList notices={notices} />
      {modal.isOpen && <AddGuideNoticeDialog modal={modal} />}
    </StyledPaper>
  );
}

function NoticeList({ notices }: { notices: Notice[] }) {
  const [showDeletedNotices, setShowDeletedNotices] = useState(false);

  if (notices.length === 0)
    return (
      <Typography variant="subtitle1" color="textSecondary">
        Ingen merknader
      </Typography>
    );

  const deletedNotices = notices
    .filter((x) => x?.deletedAt)
    .sort(
      SortBy('createdAt', false, function (a: string) {
        return a.toUpperCase();
      }),
    );
  const activeNotices = notices.filter((x) => !x?.deletedAt);

  const visibleNotices = showDeletedNotices ? activeNotices.concat(deletedNotices) : activeNotices;

  return (
    <div>
      <FormControlLabel
        control={<Switch checked={showDeletedNotices} onChange={(e) => setShowDeletedNotices(e.target.checked)} />}
        label="Vis slettede merknader"
      />
      {visibleNotices.map((x) => {
        if (!x) return null;
        return <NoticeListItem key={x.id} notice={x} />;
      })}
    </div>
  );
}

function NoticeListItem({ notice }: { notice: NonNullable<Notice> }) {
  return (
    <Accordion sx={{ margin: 0, padding: 0 }}>
      <AccordionSummary sx={{ backgroundColor: notice.deletedAt ? '#cfd4d1' : 'white' }} expandIcon={<ExpandMoreIcon />}>
        <Grid container direction="row">
          <Grid sx={{ verticalAlign: 'middle', display: 'inline-flex', alignItems: 'start', paddingRight: '0.5em', whiteSpace: 'pre' }}>
            <ListItemIcon>
              <Notice color />
            </ListItemIcon>
          </Grid>
          <Grid sx={{ flexGrow: 1 }}>
            <Typography>{notice.title}</Typography>
          </Grid>
          <Grid sx={{ paddingLeft: '0.5em' }}>
            <Typography noWrap>{[notice.createdBy?.name, formatDate(notice.createdAt)].filter(Boolean).join(', ')}</Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="column">
          <Grid item>
            <PreWrap variant="body1">{notice.notice}</PreWrap>
          </Grid>
          <Grid item>
            <ActionPanel notice={notice} />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

function ActionPanel({ notice }: { notice: NonNullable<Notice> }) {
  if (notice.deletedAt) {
    return (
      <div>
        <Grid sx={{ paddingTop: '0.5em' }}>
          <Typography noWrap>{`slettet av: ${notice.deletedBy?.name}, ${formatDate(notice.deletedAt)}`}</Typography>
        </Grid>
      </div>
    );
  }
  return <NoticeActions notice={notice} />;
}
